import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import SideBar from '../sideBar/sideBar';
import { Button, Spin, DatePicker, Typography, Radio, Switch } from 'antd';
import { BarChartOutlined, LineChartOutlined } from '@ant-design/icons';
import * as moment from 'moment';
import { LineChart, Line, XAxis, YAxis, Tooltip, Bar, Cell, ComposedChart, Scatter, Area } from 'recharts';

const { RangePicker } = DatePicker
const { Title } = Typography;

const OrdersSummary = (props) => {

    const [isLoding, setIsLoding] = useState(false)
    const [startDate, setStartDate] = useState(moment(moment(new Date()).subtract(60, 'd')).format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [graphData, setGraphData] = useState([])
    const [graphType, setGraphType] = useState('Bar')
    const [pastWeekOrders, setPastWeekOrders] = useState(true)
    const [pastMonthDayAvg, setPastMonthDayAvg] = useState(true)
    const [PastMonthMovingAvg, setPastMonthMovingAvg] = useState(true)
    const authToken = useSelector((state) => state.login.authToken)

    useEffect(() => {
        fetchReport(startDate, endDate)
        //eslint-disable-next-line
    }, []);

    const avgOfFiveWeek = (a, xi) => {
        var i = 0
        for (let step = xi - 29; ;) {
            i += (a[step].total)
            step = step + 7
            if (step >= xi) {
                break
            }
        }
        return i / 5;
    }

    const avgOfMonth = (data, xIndex) => {
        var sum = 0
        for (let step = xIndex - 28; ;) {
            sum += (data[step].total)
            step += 1
            if (step >= xIndex) {
                break
            }
        }
        var req = sum/28
        return req.toFixed(2)  // cliping the output to 2 digits
    }

    const fetchReport = (startDate, endDate) => {
        setIsLoding(true)
        let url = Apis.ordersSummary(startDate, endDate);
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setGraphData(resData.map((x, xi) => (
                    {
                        date: `${moment(x.eta__date, 'YYYY-MM-DD').format("DD-MM")}`,
                        orders: x.total,
                        color: moment(x.eta__date, 'YYYY-MM-DD').format('ddd') === 'Sun' || moment(x.eta__date, 'YYYY-MM-DD').format('ddd') === 'Sat' ? '#f59d9a' : '#25d4f7',
                        pastWeekCount: resData[xi - 7] && resData[xi - 7].total,
                        fiveWeekAvg: xi > 28 ? avgOfFiveWeek(resData, xi) : null,
                        monthmMoveingAvg: xi > 28 ? avgOfMonth(resData, xi) : null
                    })))
                setIsLoding(false)
            })
            .catch(err => {
                console.log("Err", err)
                setIsLoding(false)
            });
    }

    const onRangeChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    }

    const onChangeGraph = (e) => {
        setGraphType(e.target.value);
    };

    const onPastWeekOrdersChange = (e) => {
        setPastWeekOrders(e)
    }

    const onPastMonthDayAvgChange = (e) => {
        setPastMonthDayAvg(e)
    }

    const onPastMonthMovingAvgChange = (e) => {
        setPastMonthMovingAvg(e)
    }

    const add = (accumulator, a) => {
        return accumulator + a;
    }

    const getAvarageOrders = (ordersList) => {
        let total = ordersList.map(x => (x.orders)).reduce(add, 0);
        return { avg: parseInt(total / ordersList.length), total: total }
    }

    const ordersSummary = <div>
        <div>
            {isLoding === true && <Spin size="large" className="spinner" />}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <RangePicker
                    defaultValue={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                    format={'YYYY-MM-DD'}
                    onChange={onRangeChange}
                />
                <Button onClick={() => fetchReport(startDate, endDate)}>Go</Button>
            </div>
            {graphType === 'Bar' && <div>
                <p>Past Week Orders: <Switch size="small" defaultChecked onChange={onPastWeekOrdersChange} /></p>
                <p>Past Month Day Avg: <Switch size="small" defaultChecked onChange={onPastMonthDayAvgChange} /></p>
                <p>Month Moving Avg: <Switch size="small" defaultChecked onChange={onPastMonthMovingAvgChange} /></p>
            </div>}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 20 }}>
                    <Radio.Group
                        options={[
                            { label: <BarChartOutlined />, value: 'Bar' },
                            { label: <LineChartOutlined />, value: 'Line' }
                        ]}
                        onChange={onChangeGraph}
                        value={graphType}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>
                <div style={{ marginRight: 30 }}>
                    <p>Avarage Orders: <Title>{getAvarageOrders(graphData).avg}</Title></p>
                </div>
                <div>
                    <p>Total Orders: <Title>{getAvarageOrders(graphData).total}</Title></p>
                </div>
            </div>
        </div>
        <div style={{ alignItems: 'center' }}>
            {graphType === 'Bar' ? <ComposedChart
                width={1200}
                height={500}
                data={graphData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis xAxisId={0} dataKey='date' />
                <YAxis />
                <Tooltip />
                <Bar dataKey="orders" name="Orders">{
                    graphData.map((entry) => (
                        <Cell fill={entry.color} />
                    ))
                }
                </Bar>
                {pastWeekOrders && <Scatter dataKey="pastWeekCount" name="Past Week Orders" fill="#42f54b" stroke="#04d10e" />}
                {pastMonthDayAvg && <Area type="monotone" dataKey="fiveWeekAvg" name="Past Month Day Avg" fill="#f7c65c" stroke="#f7c65c" fillOpacity={0.2} />}
                {PastMonthMovingAvg && <Line type="monotone" dataKey="monthmMoveingAvg" name="Month Moving Avg" stroke="#47549e" />}
            </ComposedChart> :
                <LineChart
                    width={1200}
                    height={500}
                    data={graphData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="orders" />
                </LineChart>
            }
        </div>
    </div>
    return <SideBar componentToRender={ordersSummary} />
}

export default OrdersSummary