import React, { useState } from 'react';
import { Steps, Modal, Select, Input, message, Button } from 'antd';
import * as moment from 'moment';
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import { useSelector } from 'react-redux'
import '../timeLine/timeLine.css'
import orderPlaced from '../../assets/order_placed.png'
import cooking from '../../assets/cooking.png'
import foodDelivery from '../../assets/food-delivery.png'
import orderCancel from '../../assets/orderCancel.png'
import preparedFinal from '../../assets/preparedFinal.png'
import preparedInitial from '../../assets/preparedInitial.png'
import cookingInitial from '../../assets/cookingInitial.png'
import deliveryInitial from '../../assets/deliveryInitial.png'
import deliveryFinal from '../../assets/deliveryFinal.png'
import foodDeliveryInitial from '../../assets/food-deliveryInitial.png'
import partiallyDelivered from '../../assets/PartiallyDelivered.png'
import undelivered from '../../assets/undelivered.png'

const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;

const TimeLine = (props) => {
    const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const [statusInDialog, setStatusInDialog] = useState(null)
    const [noteForStatus, setNoteForStatus] = useState(null)
    const [noteForCancel, setNoteForCancel] = useState(null)

    const authToken = useSelector((state) => state.login.authToken)
    const presentStatus = props.data.details.status

    const changeStatus = (note, iscancel) => {
        let url = Apis.changeStatus(props.data.details.order_id, statusInDialog);
        if (iscancel) {
            url = Apis.changeStatus(props.data.details.order_id, 'ORDER_CANCELLED');
        }
        if (note) {
            url = url + `?note=${note}`
        }
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                message.success(resData.message);
                setNoteForStatus(null)
                setStatusInDialog(null)
                props.fetchOrderDetails()
                // return resData
            })
            .catch(err => {
                message.error(`Can't Confirm Order`);
                return err
            });
    }

    const onChangeStatus = () => {
        setIsStatusModalVisible(true);
    };

    const handleStatusOk = () => {
        if (statusInDialog === "ORDER_PARTIALLY_DELIVERED" || statusInDialog === "ORDER_UNDELIVERED") {
            if (!noteForStatus || !noteForStatus.trim()) {
                message.error(`Need to add note for Partially Delivered/Undelivered Orders`);
            } else {
                changeStatus(noteForStatus)
                setIsStatusModalVisible(false);
            }
        } else {
            changeStatus()
            setIsStatusModalVisible(false);
        }
    };

    const handleStatusCancel = () => {
        setIsStatusModalVisible(false);
        setNoteForStatus(null)
        setStatusInDialog(null)
    };

    const handleStatusChange = (value) => {
        setStatusInDialog(value)
    }

    const onNoteForStatusChange = (e) => {
        setNoteForStatus(e.target.value)
    }

    const onCancelOrder = () => {
        setIsCancelModalVisible(true);
    }

    const handleCancelOk = () => {
        if (!noteForCancel) {
            message.error(`Need to add note for Cancellation of an order`);
        } else {
            changeStatus(noteForCancel, true)
            setIsCancelModalVisible(false)
        }
    }

    const handleCancelCancel = () => {
        setIsCancelModalVisible(false)
        setNoteForCancel(null)
    }

    const onNoteForCancelOrder = (e) => {
        setNoteForCancel(e.target.value)
    }

    const modalEle = <Modal
        title="Change Order Status"
        visible={isStatusModalVisible}
        onOk={handleStatusOk}
        onCancel={handleStatusCancel}
    >
        <p>Select the current state of the order: &nbsp;&nbsp;
            <Select style={{ width: 150 }} onChange={handleStatusChange} value={statusInDialog}>
                <Option value="ORDER_PREPARING" disabled={props.data.details?.status !== "ORDER_PLACED"}>Preparing</Option>
                <Option
                    value="ORDER_PREPARED"
                    disabled={["ORDER_PREPARED", "ORDER_OUT_FOR_DELIVERY", "ORDER_DELIVERED", "ORDER_PARTIALLY_DELIVERED", "ORDER_UNDELIVERED"].includes(presentStatus)}
                >
                    Prepared
                </Option>
                <Option
                    value="ORDER_OUT_FOR_DELIVERY"
                    disabled={["ORDER_OUT_FOR_DELIVERY", "ORDER_DELIVERED", "ORDER_PARTIALLY_DELIVERED", "ORDER_UNDELIVERED"].includes(presentStatus)}
                >
                    Out for Delivery
                </Option>
                <Option
                    value="ORDER_DELIVERED"
                    disabled={["ORDER_DELIVERED", "ORDER_PARTIALLY_DELIVERED", "ORDER_UNDELIVERED"].includes(presentStatus)}
                >
                    Delivered
                </Option>
                <Option
                    value="ORDER_PARTIALLY_DELIVERED"
                    disabled={["ORDER_DELIVERED", "ORDER_PARTIALLY_DELIVERED", "ORDER_UNDELIVERED"].includes(presentStatus)}
                >
                    Partially Delivered
                </Option>
                <Option
                    value="ORDER_UNDELIVERED"
                    disabled={["ORDER_DELIVERED", "ORDER_PARTIALLY_DELIVERED", "ORDER_UNDELIVERED"].includes(presentStatus)}
                >
                    Undelivered
                </Option>
            </Select>
        </p>
        {
            (statusInDialog === "ORDER_PARTIALLY_DELIVERED" || statusInDialog === "ORDER_UNDELIVERED") &&
            <TextArea rows={2} value={noteForStatus} onChange={onNoteForStatusChange} placeholder="Add Note" />
        }
    </Modal>

    const cancelModalEle = <Modal
        title="Cancel your Order"
        visible={isCancelModalVisible}
        onOk={handleCancelOk}
        onCancel={handleCancelCancel}
    >
        <TextArea rows={2} value={noteForCancel} onChange={onNoteForCancelOrder} placeholder="Reason for cancellation" />
    </Modal>

    return (
        <div>
            {modalEle}
            {cancelModalEle}
            {!(props.origin === 'unAuthVendor' || props.origin === 'unAuthPassenger') &&
                <div className='statusButton'>
                    {/* <span> Click here to Change Status --&gt; </span> */}
                    {/* <Button type="primary" shape="circle" icon={<ThunderboltOutlined />} /> */}
                    <Button
                        onClick={onChangeStatus}
                        disabled={[
                            "ORDER_DELIVERED", "ORDER_PARTIALLY_DELIVERED",
                            "ORDER_UNDELIVERED", "ORDER_CANCELLED"].includes(presentStatus)}
                    >
                        Change Status
                    </Button>
                    <Button
                        onClick={onCancelOrder}
                        disabled={[
                            "ORDER_DELIVERED", "ORDER_PARTIALLY_DELIVERED",
                            "ORDER_UNDELIVERED", "ORDER_CANCELLED"].includes(presentStatus)}
                    >
                        Cancel Order
                    </Button>
                </div>}
            <div>
                {props.data.details.status === 'ORDER_CANCELLED' ?
                    <Steps progressDot current={1} style={{ marginTop: '20px', marginBottom: '20px' }} status="error" responsive>
                        <Step
                            title="Placed"
                            description={<img src={orderPlaced} alt="Order Placed" className="tablelogo" />}
                        />
                        <Step
                            title="Cancelled"
                            description={<img src={orderCancel} alt="Order Cancelled" className="tablelogo" />}
                        />
                    </Steps> :
                    <Steps progressDot current={props.currentStep} style={{ marginTop: '20px', marginBottom: '20px' }} responsive>
                        <Step
                            title={<img src={orderPlaced} alt="Order Placed" className="tablelogo" />}
                            description="Placed"
                        />
                        <Step
                            description="Confirmed"
                            title={props.currentStep > 0 ? <img src={cooking} alt="Order Placed" className="tablelogo" /> : <img src={cookingInitial} alt="Order Placed" className="tablelogo" />}
                        />
                        <Step
                            description="Prepared"
                            title={props.currentStep > 1 ? <img src={preparedFinal} alt="Order Placed" className="tablelogo" /> : <img src={preparedInitial} alt="Order Placed" className="tablelogo" />}
                        />
                        <Step
                            description="Out for delivery"
                            title={props.currentStep > 2 ? <img src={deliveryFinal} alt="Order Placed" className="tablelogo" /> : <img src={deliveryInitial} alt="Order Placed" className="tablelogo" />}
                        />
                        <Step
                            description={props.data.details.status === 'ORDER_PARTIALLY_DELIVERED' ? 'Partially Delivered' :
                                props.data.details.status === 'ORDER_UNDELIVERED' ? 'Undelivered' : "Delivered"}
                            subTitle={`ETA - ${moment(props.data.details.eta).format('LT')}`}
                            title={props.currentStep > 3 ? props.data.details.status === 'ORDER_PARTIALLY_DELIVERED' ? <img src={partiallyDelivered} alt="Order Delivered" className="tablelogo" /> :
                            props.data.details.status === 'ORDER_UNDELIVERED' ? <img src={undelivered} alt="Order Delivered" className="tablelogo" /> : <img src={foodDelivery} alt="Order Delivered" className="tablelogo" /> : <img src={foodDeliveryInitial} alt="Order Delivered" className="tablelogo" />}
                        />
                    </Steps>}
            </div>
        </div>
    )
}

export default TimeLine;