import React, { useEffect, useState, useRef, Fragment } from 'react';
import {
    Table, Descriptions, Typography, Tabs, Button, Tooltip, message, Upload, Image, Modal,
    Form, Input, Switch, TimePicker, InputNumber, Select, Tag, Row, Col, DatePicker, Spin
} from 'antd';
import './outletDetails.css'
import SideBar from '../sideBar/sideBar'
import { useSelector } from 'react-redux'
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import Papa from "papaparse";
import { UndoOutlined, UploadOutlined } from '@ant-design/icons';
// import { DownloadOutlined } from '@ant-design/icons';
import templateCSV from '../../assets/template_menu_upload.csv'
import * as moment from 'moment';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const OutletDetails = (props) => {

    const ref = useRef();

    const callback = (key) => {
        setActiveKey(key)
    }

    const authToken = useSelector((state) => state.login.authToken);
    // console.log(""auth token" ,authToken)

    const [outletData, setOutletData] = useState({});

    // this is for menu management tab
    const [dataForMenu, setDataForMenu] = useState([]);

    // This is for menu items display in MenuItems tab
    const [menuItemsDisplay, setMenuItemsDisplay] = useState([]);

    // This is for details display in Feedbacks tab
    const [FeedbacksDisplay, setFeedbacksDisplay] = useState([]);

    // This is for details display in Feedbacks Aggeraged detailstab
    const [SFeedbacksDisplay, setSFeedbacksDisplay] = useState({});

    // Mutated Menu Obj
    const [mutatedMenu, setMutatedMenu] = useState([]);

    // Key used for changing the Tabs
    const [activeKey, setActiveKey] = useState("1")

    // Modal for Edit visibility controlled here
    const [visible, setVisible] = useState(false);

    // Modal for Delete visibility controlled here
    const [isDeleteVisible, setIsDeleteVisible] = React.useState(false);
    const [confirmDeleteLoading, setConfirmDeleteLoading] = React.useState(false);

    // Record to Edit while editing a row in the table
    const [recordToEdit, setRecordToEdit] = useState({})

    // Record to Delete
    const [recordToDelete, setRecordToDelete] = useState({})


    // Food Type
    const [foodTypes, setFoodTypes] = useState([])

    // Cuisines
    const [cuisines, setCuisines] = useState([])

    const getOutletDetails = (stationCode) => {
        let url = Apis.getOutletDetails(props.match.params.outletId)
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setOutletData(resData)

                // calling menu items
                getMenuItems(resData.id)
                //calling feedbacks
                getFeedbacks(props.match.params.outletId, startDate, endDate)
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    const getMenuItems = (id) => {
        let url = Apis.getMenuItems(id)
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setMenuItemsDisplay(resData.map(x => {
                    x.outlet = outletData.id
                    return x
                }))
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    // No Authorization need for cuisines
    const getCuisines = () => {
        HttpUtils.Get(Apis.getCuisinesAvailable
        )
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setCuisines(resData)
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    // No Authorization need for foodTypes
    const getFoodTypes = () => {
        HttpUtils.Get(Apis.getFoodTypeAvailable)
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setFoodTypes(resData)
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    const uploadMenuItems = () => {
        return HttpUtils.Post(Apis.createMenuItems, dataForMenu,
            { Authorization: 'Token ' + authToken },
            { 'content-type': 'multipart/form-data' })
            .then(res => {
                const data = JSON.parse(JSON.stringify(res))['data'];
                message.success(`Successfully added ${data.length} Menu Items`);
                getMenuItems(outletData.id)
                setActiveKey("2")
                onResetClick()
            })
            .catch(err => {
                let tempMutatedMenu = mutatedMenu.map((x, index) => {
                    Object.keys(err.response.data[index]).map(y => {
                        x[y].errorMsg = err.response.data[index][y][0]
                        x[y].error = true
                        return y
                    })
                    return x
                })
                setMutatedMenu(tempMutatedMenu)
                message.error('Please check the table for error messages');
                return Promise.reject(err && err.response &&
                    err.response.data &&
                    err.response.data.message ? err.response.data.message : 'Network Error');
            })
    }

    const getFeedbacks = (id, startDate, endDate) => {
        setIsLoding(true)
        let url = Apis.getFeedbacks(id, startDate, endDate)
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setSFeedbacksDisplay(resData)
                var data = []
                for (const feedback of resData.feedbacks) {
                    if (feedback.order){
                        var items = []
                        for (const i in feedback.order.orderItems){
                            const item =  feedback.order.orderItems[i]
                            items.push(item.itemName + '×' + item.quantity +'\n')
                        }
                    }
                    const temp_obj = {
                        'id': feedback.id,
                        'starRating': feedback.starRating,
                        'isComplaint': feedback.isComplaint,
                        'feedbackText' : feedback.data.feedbackText,
                        'order_date': feedback.order_date,
                        'items': items,
                        'amountPayable': feedback.data.amountPayable,
                        'train_no': feedback.train_no,
                    }
                    data.push(temp_obj)
                }
                setFeedbacksDisplay(data)
                setIsLoding(false)
            })
            .catch(err => {
                message.error(err.message);
                setIsLoding(false)
            });
    }

    const onResetClick = () => {
        setDataForMenu([])
        setMutatedMenu([])
        // Resetting ref value using useRef  
        // https://thewebdev.info/2021/05/29/how-to-reset-a-file-inputs-value-in-react/
        ref.current.value = "";
    }

    useEffect(() => {
        getOutletDetails()
        getCuisines()
        getFoodTypes()
        //getFeedbacks()
        //eslint-disable-next-line
    }, []);

    const onMenuItemEdit = (record) => {
        // Formatting the record to make sure it goes into the Form component
        let formattedRecord = {
            ...record, openingTime: moment(record.openingTime, 'hh:mm:ss'),
            closingTime: moment(record.closingTime, 'hh:mm:ss')
        }

        // Changing the form values based on re-edit
        form.setFieldsValue(formattedRecord);
        setRecordToEdit(record)
        setVisible(true);
    }

    const handleCancel = () => {
        setRecordToEdit({});
        setVisible(false);
    };

    const onFinish = (values) => {
        // Formatting the values so that it will match the backend formats
        let formattedChangedValues = {
            ...values, closingTime: moment(values.closingTime).format('HH:mm:ss'),
            openingTime: moment(values.openingTime).format('HH:mm:ss'),
            tax: values.tax.toString(),
            basePrice: values.basePrice.toString()
        }

        // Keys we need to check for Edit
        let keysToCheckForEdit = Object.keys(formattedChangedValues)

        // Exact keys to edit known after the loop runs over the all the elements of the object
        let keysToEdit = []
        for (let i = 0; i < keysToCheckForEdit.length; i++) {
            if (formattedChangedValues[keysToCheckForEdit[i]] !== recordToEdit[keysToCheckForEdit[i]]) {
                keysToEdit.push(keysToCheckForEdit[i])
            }
        }

        // Getting the final patch object ready to push to the api
        let finalPatchReadyObj = {}
        keysToEdit.forEach(x => {
            finalPatchReadyObj[x] = formattedChangedValues[x]
        })

        return HttpUtils.Patch(Apis.menuItemOperations(recordToEdit.itemId), finalPatchReadyObj,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                // const data = JSON.parse(JSON.stringify(res))['data'];
                message.success(`Successfully Changed Menu Items for id: ${recordToEdit.itemId}`);
                getMenuItems(outletData.id)
                handleCancel()
            })
            .catch(err => {
                // console.log("eerrr", err.response.data)
                message.error('Cannot Edit with the given values');
                handleCancel()
                return Promise.reject(err && err.response &&
                    err.response.data &&
                    err.response.data.message ? err.response.data.message : 'Network Error');
            })
    };

    const [form] = Form.useForm();

    const onMenuItemDelete = (row) => {
        setRecordToDelete(row)
        setIsDeleteVisible(true);
    };

    const handleDeleteOk = () => {
        setConfirmDeleteLoading(true);
        return HttpUtils.Delete(Apis.menuItemOperations(recordToDelete.itemId),
            { Authorization: 'Token ' + authToken })
            .then(res => {
                // const data = JSON.parse(JSON.stringify(res))['data'];
                message.success(`Successfully Deleted Menu Item for id: ${recordToDelete.itemId}`);
                getMenuItems(outletData.id)
                setIsDeleteVisible(false);
                setConfirmDeleteLoading(false);
                handleDeleteCancel()
            })
            .catch(err => {
                // message.error('Cannot Edit with the given values');
                handleDeleteCancel()
                setIsDeleteVisible(false);
                setConfirmDeleteLoading(false);
                return Promise.reject(err && err.response &&
                    err.response.data &&
                    err.response.data.message ? err.response.data.message : 'Network Error');
            })
    };

    const handleDeleteCancel = () => {
        setIsDeleteVisible(false);
        setRecordToDelete({})
    };

    const modalEleForDelete = <Modal
        title="Delete Menu Item"
        visible={isDeleteVisible}
        onOk={handleDeleteOk}
        confirmLoading={confirmDeleteLoading}
        onCancel={handleDeleteCancel}
    >
        <p>Are you sure you want to Delete Row </p>
        <p> {recordToDelete.itemId}: {recordToDelete.itemName}</p>
    </Modal>

    const modalEleForEdit = <Modal
        title="Edit Menu Item Details"
        visible={visible}
        onCancel={handleCancel}
        footer={[
            <Fragment>
                <Button form="editMenu" key="submit" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={handleCancel}>
                    Cancel
                </Button>
            </Fragment>
        ]}
    >
        <Form
            name="editMenu"
            //   labelCol={{
            //     span: 8,
            //   }}
            //   wrapperCol={{
            //     span: 16,
            //   }}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
        >
            <Form.Item
                label="Item Name"
                name="itemName"
                rules={[
                    {
                        required: true,
                        message: 'Please input the Item Name!',
                    },
                ]}
            >
                <Input maxLength={63} />
            </Form.Item>
            <Form.Item
                label="Description"
                name="description"
                rules={[
                    {
                        required: true,
                        message: 'Please input the Description!',
                    },
                ]}
            >
                <Input maxLength={63} />
            </Form.Item>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        label="Opening Time"
                        name="openingTime"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the Opening Time!',
                            },
                        ]}
                    >
                        <TimePicker format='HH:mm:ss' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Closing Time"
                        name="closingTime"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the Closing Time!',
                            },
                        ]}
                    >
                        <TimePicker format='HH:mm:ss' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={16}>
                    <Form.Item
                        label="Cuisine"
                        name="cuisine"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the Cuisine!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select A Cuisine"
                            allowClear
                        >
                            {
                                cuisines && cuisines.map(x => {
                                    return <Option value={x.name} key={x.id}>{x.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Is Vegeterian"
                        name="isVegeterian"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                message: 'Please input whether vegeterian or not!',
                            },
                        ]}
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={11}>
                    <Form.Item
                        label="Food Type"
                        name="foodType"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the Food Type!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select A Food Type"
                            allowClear
                        >
                            {
                                foodTypes && foodTypes.map(x => {
                                    return <Option value={x.name} key={x.id}>{x.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Active"
                        name="is_active"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                message: 'Please input whether active or not!',
                            },
                        ]}
                    >
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        label="Featured"
                        name="featured"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                message: 'Please input whether itemis featured or not!',
                            },
                        ]}
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        label="Base Price"
                        name="basePrice"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the Base Price!',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Tax"
                        name="tax"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Tax!',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Is Active',
            dataIndex: 'isActiveDisplay',
            key: 'isActiveDisplay',
        }];

    const columnsForMenuManagement = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'itemName',
            render: itemName => (
                <>
                    <div
                        style={{
                            backgroundColor: itemName.error ? 'lightgoldenrodyellow' : '',
                            color: itemName.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={itemName.error ? itemName.errorMsg : ''}>
                            {itemName.name.length === 0 && itemName.error ?
                                'Error' :
                                itemName.name.toUpperCase()}
                        </Tooltip>
                    </div>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: description => (
                <>
                    <div
                        style={{
                            backgroundColor: description.error ? 'lightgoldenrodyellow' : '',
                            color: description.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={description.error ? description.errorMsg : ''}>
                            {description.name.length === 0 && description.error ?
                                'Error' :
                                description.name.toUpperCase()}
                        </Tooltip>
                    </div>
                </>
            ),
            ellipsis: true,
        },
        {
            title: 'Base Price',
            dataIndex: 'basePrice',
            key: 'basePrice',
            render: basePrice => (
                <>
                    <div
                        style={{
                            backgroundColor: basePrice.error ? 'lightgoldenrodyellow' : '',
                            color: basePrice.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={basePrice.error ? basePrice.errorMsg : ''}>
                            {basePrice.name.length === 0 && basePrice.error ?
                                'Error' :
                                basePrice.name.toUpperCase()}
                        </Tooltip>
                    </div>
                </>
            ),
        },
        {
            title: 'Opening Time',
            dataIndex: 'openingTime',
            key: 'openingTime',
            render: openingTime => (
                <>
                    <div
                        style={{
                            backgroundColor: openingTime.error ? 'lightgoldenrodyellow' : '',
                            color: openingTime.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={openingTime.error ? openingTime.errorMsg : ''}>
                            {openingTime.name.length === 0 && openingTime.error ?
                                'Error' :
                                openingTime.name.toUpperCase()}
                        </Tooltip>
                    </div>
                </>
            )
        },
        {
            title: 'Closing Time',
            dataIndex: 'closingTime',
            key: 'closingTime',
            render: closingTime => (
                <>
                    <div
                        style={{
                            backgroundColor: closingTime.error ? 'lightgoldenrodyellow' : '',
                            color: closingTime.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={closingTime.error ? closingTime.errorMsg : ''}>
                            {closingTime.name.length === 0 && closingTime.error ?
                                'Error' :
                                closingTime.name.toUpperCase()}
                        </Tooltip>
                    </div>
                </>
            )
        },
        {
            title: 'Is Vegeterian',
            dataIndex: 'isVegeterian',
            key: 'isVegeterian',
            render: isVegeterian => (
                <>
                    <div
                        style={{
                            backgroundColor: isVegeterian.error ? 'lightgoldenrodyellow' : '',
                            color: isVegeterian.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={isVegeterian.error ? isVegeterian.errorMsg : ''}>
                            {isVegeterian.name.length === 0 && isVegeterian.error ?
                                'Error' :
                                isVegeterian.name.toUpperCase()}
                        </Tooltip>
                    </div>
                </>
            )
        },
        {
            title: 'Cuisine',
            dataIndex: 'cuisine',
            key: 'cuisine',
            render: cuisine => (
                <>
                    <div
                        style={{
                            backgroundColor: cuisine.error ? 'lightgoldenrodyellow' : '',
                            color: cuisine.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={cuisine.error ? cuisine.errorMsg : ''}>
                            {cuisine.name.length === 0 && cuisine.error ?
                                'Error' :
                                cuisine.name.toUpperCase()}
                        </Tooltip>
                    </div>
                </>
            )
        },
        {
            title: 'Food Type',
            dataIndex: 'foodType',
            key: 'foodType',
            render: foodType => (
                <>
                    <div
                        style={{
                            backgroundColor: foodType.error ? 'lightgoldenrodyellow' : '',
                            color: foodType.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={foodType.error ? foodType.errorMsg : ''}>
                            {foodType.name.length === 0 && foodType.error ?
                                'Error' :
                                foodType.name.toUpperCase()}
                        </Tooltip>
                    </div>
                </>
            )
        },
        {
            title: 'Row Errors',
            dataIndex: 'non_field_errors',
            key: 'non_field_errors',
            render: non_field_errors => (
                <>{
                    non_field_errors &&
                    <div
                        style={{
                            backgroundColor: non_field_errors.error ? 'lightgoldenrodyellow' : '',
                            color: non_field_errors.error ? 'red' : ''
                        }}
                    >
                        <Tooltip title={non_field_errors.error ? non_field_errors.errorMsg : ''}>
                            {non_field_errors.error ? 'Error' : 'None'}
                        </Tooltip>
                    </div>
                }
                </>
            )
        },
    ];

    const columnsForMenuItems = [
        {
            title: 'ID',
            dataIndex: 'itemId',
            key: 'itemId',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image, record) => (
                <>
         {image ? (
                <div style={{ position: 'relative', width: '100px' }}>
                    {/* Display the image */}
                    <Image
                        width={100}
                        src={image}
                    />

                    {/* Show a small upload button in the bottom left corner */}
                    <div style={{ position: 'absolute', bottom: 0, left: 0 }}>
                        <Upload
                            {...uploadProps(record.itemId)}
                            showUploadList={false} // Do not show the default upload list
                        >
                            <Button
                                icon={<UploadOutlined />}
                                size="small"
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent background
                                    border: 'none', // No border
                                    color: 'white', // White icon for visibility
                                    borderRadius: '50%',
                                    padding: '4px',
                                    opacity: .5, // Slight opacity for blending
                                    transition: 'opacity 0.3s ease', // Smooth hover effect
                                }}
                                onMouseEnter={e => e.target.style.opacity = 1} // Hover: fully visible
                                onMouseLeave={e => e.target.style.opacity = 0.7} // Hover out: slight opacity
                            />
                        </Upload>
                    </div>
                </div>
            ) : (
                /* If no image, display the regular upload button */
                <Upload {...uploadProps(record.itemId)}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
            )}
                </>
            )
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'itemName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: 'Is Active',
            dataIndex: 'is_active',
            key: 'is_active',
            render: is_active => (
                <>
                    {is_active ?
                        <Tag color='green'>
                            Active
                        </Tag> :
                        <Tag color='volcano'>
                            InActive
                        </Tag>
                    }
                </>
            )
        },
        {
            title: 'Base Price',
            dataIndex: 'basePrice',
            key: 'basePrice',
        },
        {
            title: 'tax',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: 'Selling Price',
            dataIndex: 'sellingPrice',
            key: 'sellingPrice',
        },
        {
            title: 'Opening Time',
            dataIndex: 'openingTime',
            key: 'openingTime',
        },
        {
            title: 'Closing Time',
            dataIndex: 'closingTime',
            key: 'closingTime',
        },
        {
            title: 'Is Vegeterian',
            dataIndex: 'isVegeterian',
            key: 'isVegeterian',
            render: isVegeterian => (
                <>
                    {isVegeterian
                        ?
                        <Tag color='green'>
                            Veg
                        </Tag>
                        :
                        <Tag color='volcano'>
                            Non-Veg
                        </Tag>
                    }
                </>
            )
        },
        {
            title: 'Is Featured',
            dataIndex: 'featured',
            key: 'featured',
            render: featured => (
                <>
                    {featured
                        ?
                        <Tag color='green'>
                            Featured
                        </Tag>
                        :
                        <Tag color='volcano'>
                            Nope
                        </Tag>
                    }
                </>
            )
        },
        {
            title: 'Cuisine',
            dataIndex: 'cuisine',
            key: 'cuisine',
        },
        {
            title: 'Food Type',
            dataIndex: 'foodType',
            key: 'foodType'
        },
        {
            title: 'Action',
            key: 'action',
            render: (action, record) => (
                <div>
                    <Button
                        type="link"
                        onClick={() => onMenuItemEdit(record)}
                    >
                        Edit
                    </Button>
                    <Button
                        type="link"
                        onClick={() => onMenuItemDelete(record)}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    const columnsForFeedbacks = [
        {
            title: 'rating id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'starRating',
            dataIndex: 'starRating',
            key: 'starRating',
            align: 'starRating',
            sorter: (a, b) => a.starRating - b.starRating,
        },
        {
            title: 'isComplaint',
            dataIndex: 'isComplaint',
            key: 'isComplaint',
            sorter: (a, b) => a.isComplaint - b.isComplaint,
        },
        {
            title: 'feedbackText',
            dataIndex: 'feedbackText',
            key: 'feedbackText',
            width: 600
        },
        {
            title: 'order_date',
            dataIndex: 'order_date',
            key: 'order_date',
            sorter: (a, b) => moment(a.order_date) - moment(b.order_date)
        },
        {
            title: 'items',
            dataIndex: 'items',
            key: 'items',
            render: (items, row, index) => (items.map(item=>(<p>{item}</p>)))
        },
        {
            title: 'amountPayable',
            dataIndex: 'amountPayable',
            key: 'amountPayable',
            sorter: (a, b) => a.amountPayable - b.amountPayable,
        },
        {
            title: 'train_no',
            dataIndex: 'train_no',
            key: 'train_no',
            sorter: (a, b) => a.train_no - b.train_no,
        },
    ];

    const uploadProps = (id) => {
        return {
            name: 'image',
            action: Apis.updateImageForMenu(id),
            headers: {
                authorization: 'Token ' + authToken,
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    getMenuItems(outletData.id)
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        }
    }

    const outletDetails = <div>
        <Descriptions title="Outlet Details">
            <Descriptions.Item label="Id">{outletData.id}</Descriptions.Item>
            <Descriptions.Item label="Name">{outletData.name}</Descriptions.Item>
            <Descriptions.Item label="Outlet Id">{outletData.outlet_id}</Descriptions.Item>
            <Descriptions.Item label="Address">{outletData.address}</Descriptions.Item>
            <Descriptions.Item label="Station Code">{outletData.station_code}</Descriptions.Item>
            <Descriptions.Item label="City">{outletData.city}</Descriptions.Item>
            <Descriptions.Item label="State">{outletData.state}</Descriptions.Item>
            <Descriptions.Item label="Email">{outletData.email}</Descriptions.Item>
            <Descriptions.Item label="Is Active">{outletData.is_active ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Order Timing">{outletData.order_timing}</Descriptions.Item>
            <Descriptions.Item label="Opening Time">{outletData.openingTime}</Descriptions.Item>
            <Descriptions.Item label="Closing Time">{outletData.closingTime}</Descriptions.Item>
            <Descriptions.Item label="Longitude">{outletData.longitude}</Descriptions.Item>
            <Descriptions.Item label="Latitude">{outletData.latitude}</Descriptions.Item>
            <Descriptions.Item label="Company Name">{outletData.companyName}</Descriptions.Item>
            <Descriptions.Item label="Delivered By">{outletData.delivered_by}</Descriptions.Item>
            <Descriptions.Item label="Delivery Cost">{outletData.deliveryCost}</Descriptions.Item>
            <Descriptions.Item label="Details">{outletData.details}</Descriptions.Item>
            <Descriptions.Item label="FssaiNo">{outletData.fssaiNo}</Descriptions.Item>
            <Descriptions.Item label="Fssai Valid Upto">{outletData.fssaiValidUpto}</Descriptions.Item>
            <Descriptions.Item label="GST No">{outletData.gstNo}</Descriptions.Item>
            <Descriptions.Item label="Min Order Amount">{outletData.minOrderAmount}</Descriptions.Item>
            <Descriptions.Item label="Mobile">{outletData.mobile}</Descriptions.Item>
            <Descriptions.Item label="Prepaid">{outletData.prepaid}</Descriptions.Item>
            <Descriptions.Item label="Vendor Pan No.">{outletData.vendorPanNumber}</Descriptions.Item>
            <Descriptions.Item label="Featurd"> {(outletData.featured) ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Number of ratings">{outletData.n_rating}</Descriptions.Item>
            <Descriptions.Item label="Average Rating">{outletData.average_rating}</Descriptions.Item>
            <Descriptions.Item label="">
                <Image
                    width={150}
                    src={outletData.logoImage}
                />
            </Descriptions.Item>
        </Descriptions>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Title level={5} style={{ alignSelf: 'center' }}>Vendors Associated &nbsp;</Title>
            <Table
                style={{ width: '50%', border: 'groove' }}
                size='small'
                columns={columns}
                pagination={false}
                dataSource={outletData.vendors?.map(x => {
                    x.isActiveDisplay = x.is_active ? 'Yes' : 'No'
                    return x
                })}
            />
        </div>
    </div>

    let menuItemArr = dataForMenu && dataForMenu.map(x => x.itemName)

    const toFindDupli = arr => arr.filter((item, index) => arr.indexOf(item) !== index)

    let duplicateElements = toFindDupli(menuItemArr)

    const menuManagement = <div>
        <div className="menuUpload">
            <Button
                shape="round"
                style={{ marginRight: '10px' }}
            // icon={<DownloadOutlined />}
            >
                <a href={templateCSV} download="template_menu_upload">Download Template CSV</a>
            </Button>
            Upload Menu CSV: <input
                type="file"
                accept=".csv,.xlsx,.xls"
                ref={ref}
                onChange={(e) => {
                    const files = e.target.files;
                    if (files) {
                        Papa.parse(files[0], {
                            header: true,
                            skipEmptyLines: true,
                            complete: function (results) {
                                setDataForMenu(results.data.map(x => {
                                    x.outlet = outletData.id
                                    x.cuisine = x.cuisine.toUpperCase()
                                    x.foodType = x.foodType.toUpperCase()
                                    return x
                                }))
                                let mutatedDataForMenu = []
                                results.data.forEach(x => {
                                    let menuObj = {}
                                    menuObj.foodType = {
                                        name: x.foodType,
                                        error: false,
                                        errorMsg: ''
                                    }
                                    menuObj.basePrice = {
                                        name: x.basePrice,
                                        error: false,
                                        errorMsg: ''
                                    }
                                    menuObj.itemName = {
                                        name: x.itemName,
                                        error: false,
                                        errorMsg: ''
                                    }
                                    menuObj.description = {
                                        name: x.description,
                                        error: false,
                                        errorMsg: ''
                                    }
                                    menuObj.openingTime = {
                                        name: x.openingTime,
                                        error: false,
                                        errorMsg: ''
                                    }
                                    menuObj.cuisine = {
                                        name: x.cuisine,
                                        error: false,
                                        errorMsg: ''
                                    }
                                    menuObj.closingTime = {
                                        name: x.closingTime,
                                        error: false,
                                        errorMsg: ''
                                    }
                                    menuObj.isVegeterian = {
                                        name: x.isVegeterian,
                                        error: false,
                                        errorMsg: ''
                                    }
                                    menuObj.non_field_errors = {
                                        name: 'Error',
                                        error: false,
                                        errorMsg: ''
                                    }
                                    mutatedDataForMenu.push(menuObj)
                                })
                                setMutatedMenu(mutatedDataForMenu)
                            }
                        }
                        )
                    }
                }}
            />
        </div>
        {
            dataForMenu.length > 0 &&
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'normal' }}>
                    <Title level={5} style={{ marginLeft: '10px' }}>Preview </Title>
                    <Button
                        style={{
                            marginLeft: '10px',
                            marginBottom: '5px',
                            paddingBottom: '5px'
                        }}
                        shape="round"
                        onClick={uploadMenuItems}
                        disabled={duplicateElements.length > 0}
                    >
                        Upload Menu
                    </Button>
                    <Button
                        shape="round"
                        icon={<UndoOutlined />}
                        size='middle'
                        style={{
                            marginLeft: '10px',
                            marginBottom: '5px',
                            paddingBottom: '5px'
                        }}
                        onClick={onResetClick}
                    >
                        Reset
                    </Button>
                </div>
                {
                    duplicateElements.length > 0 &&
                    <Text
                        type="danger"
                        style={{ marginLeft: '25%', marginBottom: '5px' }}
                    >
                        {duplicateElements.toString()} are repeating more than once, Please check the csv
                    </Text>
                }
                <Table
                    size='small'
                    columns={columnsForMenuManagement}
                    dataSource={mutatedMenu}
                />
            </div>
        }
    </div>

    const menuItems = <div>
        <Table
            size='small'
            columns={columnsForMenuItems}
            dataSource={menuItemsDisplay}
        />
        {modalEleForEdit}
        {modalEleForDelete}
    </div>

    const { RangePicker } = DatePicker
    const [startDate, setStartDate] = useState(moment(moment(new Date()).subtract(20, 'days')).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(moment(new Date()).subtract(5, 'days')).format('YYYY-MM-DD'));
    const onRangeChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    }
    const [isLoding, setIsLoding] = useState(false)
    const feedbacks = <div>
        <Title style={{ textAlign: 'center' }} level={4}>Feedbacks With In Selected Dates </Title>
        <div className="header">
            <div>
                <RangePicker
                    defaultValue={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                    format={'YYYY-MM-DD'}
                    onChange={onRangeChange}
                />
                <Button onClick={() => getFeedbacks(props.match.params.outletId, startDate, endDate)}>Go</Button>
            </div>
        </div>
        <div>
            {isLoding === true && <Spin size="large" className="spinner" />}
        </div>
           <Descriptions title="">
            <Descriptions.Item label="Feedback Count">{SFeedbacksDisplay.feedbacks_count}</Descriptions.Item>
            <Descriptions.Item label="Orders Count">{SFeedbacksDisplay.orders_count}</Descriptions.Item>
            <Descriptions.Item label="Complaints Count">{SFeedbacksDisplay.complaints_count}</Descriptions.Item>
            <Descriptions.Item label="Average Rating">{Math.round(SFeedbacksDisplay.avg_ratings * 100) / 100}
            </Descriptions.Item>
            <Descriptions.Item label="Start Date">{SFeedbacksDisplay.start_date}</Descriptions.Item>
            <Descriptions.Item label="End Date">{SFeedbacksDisplay.end_date}</Descriptions.Item>
            {[...Array(6)].map((obj, i) =>
            <Descriptions.Item label="">{i} Rating {SFeedbacksDisplay.ratings_count ? (SFeedbacksDisplay.ratings_count[i] ? SFeedbacksDisplay.ratings_count[i] : 0) : 0}
            </Descriptions.Item>
        )}   
        </Descriptions>
        <Table
            size='small'
            columns={columnsForFeedbacks}
            dataSource={FeedbacksDisplay}
            pagination={false}
        />
    </div>


    const tabsView = <Tabs activeKey={activeKey} onChange={callback}>
        <TabPane tab="Outlet Details" key="1">
            {outletDetails}
        </TabPane>
        <TabPane tab="Menu Items" key="2">
            {menuItems}
        </TabPane>
        <TabPane tab="Menu Management" key="3">
            {menuManagement}
        </TabPane>
        <TabPane tab="Feedbacks" key="4">
            {feedbacks}
        </TabPane>
    </Tabs>

    return (
        <SideBar componentToRender={tabsView} />
    )
}

export default OutletDetails;