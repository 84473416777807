import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import './login.css'
import { getLoginOtp, verifyLoginOtp, autoRegister } from '../utility/apiServices'
import { useDispatch } from 'react-redux'
import { stopLoader, startLoader, loginSuccessful } from './loginSlice'
import { useMediaQuery } from 'react-responsive'

const { Search } = Input;

const LoginOTP = () => {

    const dispatch = useDispatch()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const [data, setData] = useState({
        sessionId: null,
        error: null,
        msg: null
    })

    const onFinish = async (values) => {
        dispatch(startLoader())
        let auth_token = await verifyLoginOtp(data.sessionId, values["mobileNumber"], values["otp"])
        autoRegister(auth_token, values["mobileNumber"])
        dispatch(loginSuccessful({authToken: auth_token, mobileNumber: values["mobileNumber"]}))
        dispatch(stopLoader())
        message.success('successfully Logged In');
    };

    const onFinishFailed = (errorInfo) => {
        message.error(`Can't Log In`);
        console.log('Failed:', errorInfo);
    };

    const onSearch = async (value) => {

        dispatch(startLoader())
        let res = await getLoginOtp(value)
        dispatch(stopLoader())
        setData({
            ...data,
            sessionId: res.sms_session_id,
            msg: res.msg,
            error: res.error
        })
    }

    return (
        <div className={isTabletOrMobile ? 'containerDivMobile' : 'containerDiv'}>
            <Card
                title={isTabletOrMobile ? 'Dashboard OTP Login' : 'Spicy Wagon Dashboard OTP Login'}
                style={{ borderWidth: 5, borderRadius: 5 }}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className={isTabletOrMobile ? "loginMobile" : "login"}
                >
                    <Form.Item
                        label="Mobile No."
                        name="mobileNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Mobile Number!',
                            },
                        ]}
                    >
                        <Search
                            placeholder="input mobile no."
                            allowClear
                            enterButton="Generate OTP"
                            size="large"
                            onSearch={onSearch}
                        />
                    </Form.Item>

                    {
                        data.sessionId &&
                        <Form.Item
                            label="OTP"
                            name="otp"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your OTP!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    }

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default LoginOTP;