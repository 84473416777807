import config from './config';

const backendServer = config.backendServer;

const apiEndpoints = {

    // Login Stack
    // Login With Password
    getToken: `${backendServer}common/login-with-password/`,
    // Send Login OTP
    getOtp: `${backendServer}common/send-login-otp/`,
    // Verifying the OTP
    verifyOtp: `${backendServer}common/verify-otp/`,
    // Password Reset OTP
    sendResetOtp: `${backendServer}common/send-password-reset-otp/`,
    // Verifying Reset Password OTP
    verifyResetOtp: `${backendServer}common/verify-otp-password-reset/`,
    // Updating the Password
    updatePassword: `${backendServer}common/update-password/`,

    // Orders Stack
    // Orders By Date
    getOrdersByDate: (date) => `${backendServer}dashboard/orders/${date}`,
    // Orders Grouped By Station and Date
    getStationOrdersByDate: (date) => `${backendServer}dashboard/orders-count-by-station/${date}`,
    // Orders Grouped By Outlet and Date
    getOutletOrdersByDate: (date) => `${backendServer}dashboard/orders-count-by-outlet/${date}`,
    // Orders Grouped By Train and Date
    getTrainOrdersByDate: (date) => `${backendServer}dashboard/orders-count-by-train/${date}`,
    // Order Details Page
    getOrderDetails: (id) => `${backendServer}dashboard/order/${id}/details`,
    // Order Confirmation
    confirmOrder: (id) => `${backendServer}dashboard/order/${id}/confirm`,
    // Order Cancellation
    cancelOrder: (id) => `${backendServer}dashboard/order/${id}/cancel`,
    // Get Events
    getEvents: (id) => `${backendServer}dashboard/order/${id}/events`,
    // Change status end points
    changeStatus: (orderId, status) => `${backendServer}dashboard/order/${orderId}/change-status-to/${status}`,
    // orders Summary
    ordersSummary: (startDate, endDate) => `${backendServer}dashboard/orders-summary/${startDate}/${endDate}`,
    
    //Feedbacks Stack
    // Feedbacks By DateRange
    getFeedbackByDates: (startDate, endDate) => `${backendServer}dashboard/feedback-stats/?start_date=${startDate}&end_date=${endDate}`,

    // Delivery
    getAvailableExecutives: `${backendServer}delivery/operator/delivery-executive/list`,
    // Assign Delivery Executive
    assignDeliveryExecutive: `${backendServer}delivery/operator/assign-delivery-job/`,
    // Passenger Yet to Confirm Order
    passengerYetToConfirm: (date) => `${backendServer}dashboard/passenger-yet-to-confirm-orders/${date}`,
    // Vendor Yet to Confirm Order
    vendorYetToConfirm: (date) => `${backendServer}dashboard/orders/${date}?status=ORDER_PLACED`,
    // Passenger Confirm Un Authenticated
    passengerConfirmUnAuth: (pnr, orderId) => `${backendServer}open-api/passenger-order-confirm/${pnr}/${orderId}/`,
    
    // Outlets
    getOutlets: `${backendServer}dashboard/outlets`,
    // OutletDetails
    getOutletDetails: (outletId) => `${backendServer}dashboard/outlet/${outletId}/details`,

    // Create Menu Items
    createMenuItems: `${backendServer}dashboard/menu-item/create/`,
    // Get Menu Items
    getMenuItems: (outletId) => `${backendServer}dashboard/menu-item/${outletId}/list/`,
    // Editing a Menu item
    menuItemOperations: (id) => `${backendServer}dashboard/menu-item/${id}`,
    // Updating an Image for a menu item
    updateImageForMenu: (id) => `${backendServer}dashboard/menu-item/${id}/update-image`,
    
    // Cuisine
    getCuisinesAvailable: `${backendServer}cuisine`,
    // FoodType
    getFoodTypeAvailable: `${backendServer}foodtype`,

    // GetFeedbacks
    getFeedbacks: (outletId, startDate, endDate) => 
    `${backendServer}dashboard/${outletId}/feedback-stats/?start_date=${startDate}&end_date=${endDate}`,
}

export default apiEndpoints;