import HttpUtils from './httpUtils';
import Apis from '../config/apiEndpoints';
import LocalStorageVariables from '../config/localStorage';

export const checkAuthInfo = () => {
    const token = localStorage.getItem(LocalStorageVariables.auth_token);
    const mobileNumber = localStorage.getItem(LocalStorageVariables.phone_number);
    const otp = localStorage.getItem(LocalStorageVariables.otp);

    return Promise.resolve({ isLoggedIn: token ? true : false, token: token, mobileNumber: mobileNumber, otp: otp });
}

export const autoRegister = (auth_token, phone_number) => {
    localStorage.setItem(LocalStorageVariables.auth_token, auth_token);  // storing auth token in localstorage
    localStorage.setItem(LocalStorageVariables.phone_number, phone_number);
}

export const getLoginOtp = (mobNo) => {
    return HttpUtils.Post(Apis.getOtp, { 'phone_number': mobNo })
        .then(res => {
            const data = JSON.parse(JSON.stringify(res))['data'];
            return Promise.resolve({ error: data['error'], msg: data['message'], sms_session_id: data['sms_session_id'] });
        })
        .catch(err => {
            return Promise.reject(err && err.response && err.response.data && err.response.data.error ? err.response.data.error : 'Network Error');
        });
}

export const verifyLoginOtp = (sms_session_id, phone_number, otp) => {
    if (sms_session_id && phone_number && otp) {
        // verify otp api call
        return HttpUtils.Post(Apis.verifyOtp, { 'phone_number': phone_number,
         'sms_session_id': sms_session_id,
          'otp': otp })
            .then(res => {
                const data = JSON.parse(JSON.stringify(res))['data'];
                const auth_token = data['token'];
                if (auth_token) {
                    return Promise.resolve(auth_token);
                } else {
                    return Promise.reject(data.error)
                }
            })
            .catch(err => {
                return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : err);
            })
    }
}

export const passwordLogin = (values) => {
    const formData = {
        username: values["mobileNumber"],
        password: values["password"]
    }
    return HttpUtils.Post(Apis.getToken, formData, { 'content-type': 'multipart/form-data' })
        .then(res => {
            const data = JSON.parse(JSON.stringify(res))['data'];
            const auth_token = data['token'];
            if (auth_token) {
                return Promise.resolve(auth_token);
            } else {
                return Promise.reject(data.error);
            }
        })
        .catch(err => {
            return Promise.reject(err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Network Error');
        })
}

export const logout = () => {
    const localVrblsKeys = Object.keys(LocalStorageVariables);
    // remove the local storgae
    localVrblsKeys.map(localVar => {
        return localStorage.removeItem(LocalStorageVariables[localVar], '');
    });
}

// export const fetchOrdersByDate = async (date, authToken) => {
//     await HttpUtils.Get(Apis.getOrdersByDate(date),
//      { Authorization: 'Token ' + authToken })
//         .then(res => {
//             const resData = JSON.parse(JSON.stringify(res))['data'];
//             return resData
//         })
//         .catch(err => {
//             return err;
//         });
// }

// export const fetchOrderDetails = async (id, authToken) => {
//     await HttpUtils.Get(Apis.getOrderDetails(id),
//      { Authorization: 'Token ' + authToken })
//         .then(res => {
//             const resData = JSON.parse(JSON.stringify(res))['data'];
//             console.log("orderDetisl", resData)
//             return resData
//         })
//         .catch(err => {
//             return err;
//         });
// }