import React, { useEffect, useState, Fragment } from 'react';
import { Table, Card, Typography, Button, Modal, Spin, message, Divider, Form, Input, Collapse } from 'antd';
import * as moment from 'moment';
import { useSelector } from 'react-redux'
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import './orderDetails.css'
import { useParams } from "react-router-dom";
import { ShoppingTwoTone, CheckCircleTwoTone, ArrowRightOutlined } from '@ant-design/icons';
import SideBar from '../sideBar/sideBar'
import { useMediaQuery } from 'react-responsive';
import TimeLine from '../timeLine/timeLine';
import orderPlaced from '../../assets/order_placed.png'
import cooking from '../../assets/cooking.png'
import foodDelivery from '../../assets/food-delivery.png'
import deliveryFinal from '../../assets/deliveryFinal.png'
import preparedFinal from '../../assets/preparedFinal.png'
import orderCancel from '../../assets/orderCancel.png'
import undelivered from '../../assets/undelivered.png'
import partiallyDelivered from '../../assets/PartiallyDelivered.png'
import operator from '../../assets/support.png'
import robot from '../../assets/robot.png'
import restaurant from '../../assets/color_restaurant.png'
import man from '../../assets/man.png'

const { confirm } = Modal;
const { Title, Text } = Typography;
const { TextArea } = Input;

const OrderDetails = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const params = useParams()

    const authToken = useSelector((state) => state.login.authToken)

    const [data, setData] = useState({
        details: {},
        availableExecutives: [],
        selectedExecutive: {}
    })
    const [isLoding, setIsLoding] = useState(false)
    const [visible, setVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(0)

    const { Panel } = Collapse;

    function callback(key) {
        console.log(key);
    }

    const currentStepIdentifier = (a) => {
        switch (a) {
            case 'ORDER_PLACED':
                return 0
            case 'ORDER_PREPARING':
                return 1
            case 'ORDER_PREPARED':
                return 2
            case 'ORDER_OUT_FOR_DELIVERY':
                return 3
            case 'ORDER_DELIVERED':
                return 4
            case 'ORDER_PARTIALLY_DELIVERED':
                return 4
            case 'ORDER_UNDELIVERED':
                return 4
            case 'ORDER_CANCELLED':
                return 4
            default:
                return 0
        }
    }

    const fetchOrderDetails = () => {
        let url = Apis.getOrderDetails(params.id);
        setIsLoding(true)
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setData({
                    ...data,
                    details: resData
                })
                setCurrentStep(currentStepIdentifier(resData.status))
                setIsLoding(false)
            })
            .catch(err => {
                console.log("Err", err)
            });
    }

    const fetchAvailableExecutives = (stationCode) => {
        let url = Apis.getAvailableExecutives +
            `?date=${moment(params.date).format('YYYY-MM-DD')}&station_code=${stationCode}`

        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setData({
                    ...data,
                    availableExecutives: resData.map((x, index) => {
                        x.key = index + 1
                        return x
                    })
                })
            })
            .catch(err => {
                console.log("Err", err)
                setIsLoding(false)
            });
    }

    const confirmOrder = () => {
        let url = Apis.confirmOrder(params.id);
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                fetchOrderDetails()
                message.success('Order Confirmed');
                return resData
            })
            .catch(err => {
                message.error(`Can't Confirm Order`);
                return err
            });
    }

    const assignDeliveryExecs = (dataObj) => {
        // const data = {
        //     order: values["order"],
        //     delivery_executive: values["deliveryExecutive"],
        //     station_code: values["stationCode"],
        // }
        return HttpUtils.Post(Apis.assignDeliveryExecutive, dataObj,
            { Authorization: 'Token ' + authToken },
            { 'content-type': 'multipart/form-data' })
            .then(res => {
                // const data = JSON.parse(JSON.stringify(res))['data'];
                fetchOrderDetails()
            })
            .catch(err => {
                return Promise.reject(err && err.response &&
                    err.response.data &&
                    err.response.data.message ? err.response.data.message : 'Network Error');
            })
    }

    useEffect(() => {
        fetchOrderDetails()
        //eslint-disable-next-line
    }, []);

    let columns = [
        {
            title: '',
            dataIndex: 'icon',
            key: 'icon',
            render: (text, record) => <ShoppingTwoTone twoToneColor={record.isVegetarian ? "#52c41a" : "#eb2f96"} />
        },
        {
            title: 'Items',
            dataIndex: 'itemName',
            key: 'itemName',
        },
        {
            title: '#',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'sellingPrice',
            key: 'sellingPrice',
        }
    ]

    let priceDetails = data && data.details.data?.priceDetails

    // Sub Total is Total Amount minus, gst, deliveryCharge, discountAmount

    let dataForPriceTable = priceDetails && [
        // {
        //     type: 'Discount',
        //     price: priceDetails["discountAmount"],
        // },
        {
            type: 'Sub Total (Before GST)',
            price: priceDetails["totalAmount"] - priceDetails["gst"],
        },
        {
            type: 'GST',
            price: priceDetails["gst"],
        },
        {
            type: 'Total Amount',
            price: priceDetails["totalAmount"],
        },
        {
            type: 'Delivery Charge',
            price: priceDetails["deliveryCharge"],
        },
        {
            type: 'Amount Payable',
            price: priceDetails["amountPayable"],
        },
    ]
    
    let columnsForPriceBreakUp = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        }
    ]

    // let timeLineEvents = [data.details && data.details.events, orderCreatedEvent]

    const showPromiseConfirm = () => {
        confirm({
            title: 'Do you want to confirm the order?',
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            content: 'When clicked the OK button, this order will be confirmed by the operator',
            onOk() {
                confirmOrder();
            },
            onCancel() { },
        });
    }

    const onAssignOk = () => {
        const dataObj = {
            order: data.details.id,
            delivery_executive: data.selectedExecutive.id,
            station_code: data.details.data?.deliveryDetails.stationCode
        }

        assignDeliveryExecs(dataObj)
        setVisible(false)
    }

    const columnsForExecutives = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Mobile',
        dataIndex: 'phone_number',
        key: 'phone_number',
    },
    {
        title: 'Jobs',
        dataIndex: 'jobs',
        key: 'jobs',
        render: jobs => <div>{jobs.length}</div>,
    },
    ]

    const onAssignClick = () => {
        if (data.availableExecutives.length === 0) {
            fetchAvailableExecutives(data.details.data?.deliveryDetails.stationCode)
        }
        setVisible(true)
    }

    const showEventModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        const formData = {
            description: 'Operator Event',
            data: {
                note: values['note']
            }
        }
        let url = Apis.getEvents(params.id);
        return HttpUtils.Post(url,
            formData,
            { Authorization: 'Token ' + authToken },
            { 'content-type': 'multipart/form-data' })
            .then(res => {
                // const data = JSON.parse(JSON.stringify(res))['data'];
                fetchOrderDetails()
                setIsModalVisible(false);
                message.success('Event Added');
            })
            .catch(err => {
                setIsModalVisible(false);
                return Promise.reject(err && err.response &&
                    err.response.data && err.response.data.message ? err.response.data.message : 'Network Error');

            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setData({
                ...data,
                selectedExecutive: selectedRows[0]
            })
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            // Column configuration not to be checked
            phone: record.phone_number,
        }),
    };

    let isSelectedExecutiveEmpty = Object.keys(data.selectedExecutive).length === 0 &&
        data.selectedExecutive.constructor === Object;

    const addEventModal = <Modal
        title="Add an Event"
        visible={isModalVisible}
        onOk={handleOk}
        footer={[
            <Button form="eventForm" key="submit" htmlType="submit">
                Submit
            </Button>
        ]}
        onCancel={handleCancel}
    >
        <Form
            id="eventForm"
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Description"
                name="description"
            >
                <Input defaultValue="Operator Event" disabled />
            </Form.Item>
            <Form.Item
                label="Note"
                name="note"
                rules={[
                    {
                        required: true,
                        message: 'Please input Note!',
                    },
                ]}
            >
                <TextArea rows={4} />
            </Form.Item>
        </Form>
    </Modal>

    const modalDelivery = <Modal
        title="Available Executives"
        centered
        visible={visible}
        onOk={onAssignOk}
        onCancel={() => setVisible(false)}
        width={1000}
        okButtonProps={{ disabled: isSelectedExecutiveEmpty }}
        okText="Assign"
    >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Table
                columns={columnsForExecutives}
                dataSource={data.availableExecutives}
                size="small"
                pagination={false}
                style={{ width: '300px' }}
                bordered
                rowSelection={{
                    type: 'radio',
                    ...rowSelection,
                }}
            />
            {
                !isSelectedExecutiveEmpty &&
                <Card title={data.selectedExecutive.phone_number} style={{ marginLeft: '30px' }}>
                    {
                        data.selectedExecutive.jobs?.map(x =>
                            <div>
                                <p>Outlet: {x.outlet.name}</p>
                                <p>Order No: {x.order}</p>
                                <p>ETA: {moment(x.eta).format('LLL')}</p>
                                <p>ETD: {moment(x.etd).format('LLL')}</p>
                                <Divider />
                            </div>
                        )
                    }
                </Card>
            }
        </div>
    </Modal>

    let roleImageView = (role, createdBy) => {
        switch (role) {
            case 'Operator':
                return <div className="readStatusInsideRole">
                    {createdBy &&
                        <Text type="secondary">{createdBy}</Text>}
                    <img src={operator} alt="Operator" className="tablelogo" />
                </div>
            case 'Passenger':
                return <div className="readStatusInsideRole">
                    {createdBy &&
                        <Text type="secondary">{createdBy}</Text>}
                    <img src={man} alt="Passenger" className="tablelogo" />
                </div>
            case 'Chitti':
                return <div className="readStatusInsideRole">
                    {createdBy &&
                        <Text type="secondary">{createdBy}</Text>}
                    <img src={robot} alt="Chitti" className="tablelogo" />
                </div>
            case 'Vendor':
                return <div className="readStatusInsideRole">
                    {createdBy &&
                        <Text type="secondary">{createdBy}</Text>}
                    <img src={restaurant} alt="Vendor" className="tablelogo" />
                </div>
            default:
                return <div>?  </div>
        }
    }

    // Keep by beside image

    let readableStatusView = (status) => {
        switch (status) {
            case 'ORDER_PLACED':
                return <div className="readStatusInside">
                    <img src={orderPlaced} alt="Order Placed" className="tablelogo" />
                    <p>Order Placed</p>
                </div>
            case 'ORDER_PREPARING':
                return <div className="readStatusInside">
                    <img src={cooking} alt="Order Preparing" className="tablelogo" />
                    <p>Order Preparing</p>
                </div>
            case 'ORDER_PREPARED':
                return <div className="readStatusInside">
                    <img src={preparedFinal} alt="Order Prepared" className="tablelogo" />
                    <p>Order Prepared</p>
                </div>
            case 'ORDER_OUT_FOR_DELIVERY':
                return <div className="readStatusInside">
                    <img src={deliveryFinal} alt="Out for Delivery" className="tablelogo" />
                    <p>Out for Delivery</p>
                </div>
            case 'ORDER_DELIVERED':
                return <div className="readStatusInside">
                    <img src={foodDelivery} alt="Delivered" className="tablelogo" />
                    <p>Delivered</p>
                </div>
            case 'ORDER_PARTIALLY_DELIVERED':
                return <div className="readStatusInside">
                    <img src={partiallyDelivered} alt="Order Cancelled" className="tablelogo" />
                    <p>Partially Delivered</p>
                </div>
            case 'ORDER_UNDELIVERED':
                return <div className="readStatusInside">
                    <img src={undelivered} alt="Order Cancelled" className="tablelogo" />
                    <p>Undelivered</p>
                </div>
            case 'ORDER_CANCELLED':
                return <div className="readStatusInside">
                    <img src={orderCancel} alt="Order Cancelled" className="tablelogo" />
                    <p>Order Cancelled</p>
                </div>
            default:
                return <div>?  </div>
        }
    }

    const ordersDetails = <div>
        <div className="header">
            <Title level={4}>Orders Details</Title>
            {modalDelivery}
        </div>
        <TimeLine data={data} currentStep={currentStep} origin={null} fetchOrderDetails={fetchOrderDetails} />
        <Title level={5}>Order ID: {data.details.order_id}</Title>
        {addEventModal}
        <div>
            {isLoding === true && <Spin size="large" className="spinner" />}
        </div>
        {
            data.details.data &&
            <div className={isTabletOrMobile ? "containerMobile" : "containerDesktop"}>
                <div>
                    <Card title="Restuarant Details" bordered={false} className={isTabletOrMobile ? "cardMob" : "cardC1R1"}>
                        <p className={isTabletOrMobile && "para"}>Name: {data.details.data.aggregatorDetails.name}</p>
                        <p className={isTabletOrMobile && "para"}>Outlet Name: {data.details.data.aggregatorDetails.outletName}</p>
                        <p className={isTabletOrMobile && "para"}>Outlet Id: {data.details.data.aggregatorDetails.outletId}</p>
                        {/* <p>Customer Care No:
                        {data.details.data.aggregatorDetails.customerCareNumbers.map(x => <span>{x}</span>)}</p> */}
                    </Card>
                    <Card title="Passenger Details" bordered={false} className={isTabletOrMobile ? "cardMob" : "cardC1R1"}>
                        <p className={isTabletOrMobile && "para"}>Name: {data.details.data.customerDetails.customerName}</p>
                        <p className={isTabletOrMobile && "para"}>Mobile: {data.details.data.customerDetails.mobile}</p>
                        <p className={isTabletOrMobile && "para"}>Alternate Mobile: {data.details.data.customerDetails.alternateMobile}</p>
                        <p className={isTabletOrMobile && "para"}>Email: {data.details.data.customerDetails.email}</p>
                    </Card>
                </div>
                <div>
                    <Card title="Train Details" bordered={false} className={isTabletOrMobile ? "cardMob" : "cardC2"}>
                        <p className={isTabletOrMobile && "para"}>Pushed At: {moment(data.details.created_at).format('LLL')}</p>
                        <p className={isTabletOrMobile && "para"}>Booked At: {moment((data.details.data.bookingDate), 'MM-DD-YYYY HH:mm z').format('LLL')}</p>
                        <p className={isTabletOrMobile && "para"}>Berth: {data.details.data.deliveryDetails.coach} - {data.details.data.deliveryDetails.berth}</p>
                        <p className={isTabletOrMobile && "para"}>Station: {data.details.data.deliveryDetails.station} ({data.details.data.deliveryDetails.stationCode})</p>
                        <p className={isTabletOrMobile && "para"}>Train: {data.details.data.deliveryDetails.trainNo} - {data.details.data.deliveryDetails.trainName}</p>
                        <p className={isTabletOrMobile && "para"}>ETA: {moment(data.details.eta).format('LLL')}</p>
                        <p className={isTabletOrMobile && "para"}>ETD: {moment(data.details.etd).format('LLL')}</p>
                    </Card>
                    <Card
                        title="Delivery Boy Details"
                        bordered={false}
                        className={isTabletOrMobile ? "cardMob" : "cardC2"}
                        extra={
                            <Button
                                onClick={onAssignClick}
                                disabled={data.details.delivery_jobs.length > 0}
                            >
                                Assign
                            </Button>
                        }
                    >
                        {
                            data.details.delivery_jobs.length > 0 ?
                            data.details.delivery_jobs.map(x => {
                                return <Fragment>
                                    <p className={isTabletOrMobile && "para"}> Id: {x.id}, Name: {x.delivery_executive.name}</p>
                                    <p className={isTabletOrMobile && "para"}>Mobile: {x.delivery_executive.phone_number}</p>
                                </Fragment>
                             }) :
                                <p className={isTabletOrMobile && "para"}>No Delivery Executive Assigned</p>
                        }
                    </Card>
                </div>
                <div>
                    <Card title="Order Details" bordered={false} className={isTabletOrMobile ? "cardMob" : "cardC2R1"}>
                        <Button
                            onClick={showPromiseConfirm}
                            disabled={data.details.status !== 'ORDER_PLACED'}
                        >
                            Confirm
                    </Button>
                        <Button>
                            Cancel
                    </Button>
                        <Table
                            columns={columns}
                            dataSource={data.details.data.orderItems}
                            size="small"
                            pagination={false}
                            bordered
                            className="table"
                        />
                        <Collapse onChange={callback}>
                                <Panel header="Price Break Up" key="1">
                                    <Table
                                        columns={columnsForPriceBreakUp}
                                        dataSource={dataForPriceTable}
                                        size="small"
                                        pagination={false}
                                        bordered
                                        className="table"
                                    />
                                </Panel>
                            </Collapse>
                            &nbsp;
                        <p className={isTabletOrMobile && "para"}>Amount: {data.details.data.priceDetails.amountPayable}</p>
                        <p className={isTabletOrMobile && "para"}>Payment Type: {data.details.data.paymentType}</p>
                        <p className={isTabletOrMobile && "para"}>Comment: {data.details.data.comment}</p>
                        <p className={isTabletOrMobile && "para"}>Status: {data.details.status}</p>
                    </Card>
                    <Card
                        title="Events"
                        bordered={false}
                        className={isTabletOrMobile ? "cardMob" : "cardC2R1"}
                        extra={
                            <Button onClick={showEventModal}>
                                Add Event
                            </Button>
                        }
                    >
                        {
                            data.details.events.map(x => {
                                let stateChangeArr = x.data &&
                                    x.data.state_change &&
                                    x.data.state_change.match(/ORDER_\w*/g)
                                return <Card
                                    size="small"
                                    title={x.description}
                                    style={{ marginBottom: '10px' }}
                                    extra={roleImageView(x.role, x.created_by)}
                                >
                                    <p><Text italic>{x.data && !(Object.keys(x.data).length === 0 &&
                                        x.data.constructor === Object) && x.data.note}</Text></p>
                                    {stateChangeArr && stateChangeArr.length > 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                        {readableStatusView(stateChangeArr[0])}
                                        <ArrowRightOutlined />
                                        {readableStatusView(stateChangeArr[1])}
                                    </div>}
                                    <p>{moment(x.created_at).format('lll')}</p>
                                </Card>
                            })}
                    </Card>
                </div>
            </div>
        }
    </div>

    return (
        <SideBar componentToRender={ordersDetails} />
    )
}

export default OrderDetails;
