const prefix = 'spicywagon_';

const localStorageVariables = {
    'sms_session_id': prefix + 'sms_session_id',
    'auth_token': prefix + 'auth_token',
    'otp': prefix + 'otp',
    'phone_number': prefix + 'phone_number',
    'is_registered': prefix + 'is_registered',
}

export default localStorageVariables;