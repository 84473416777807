const dev = {
  backendServer: "https://engine.spicywagon.in/",
  backendMedia: "https://engine.spicywagon.in/",
}

const test = {
  backendServer: "http://3.110.21.125:8080/",
  backendMedia: "http://3.110.21.125:8080/",
}

const engineConfig = {
  backendServer: "https://engine.spicywagon.in/",
  backendMedia: "https://engine.spicywagon.in/",
}

console.log("##", process.env)

const config = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "test":
      return test;
    case "production":
      return engineConfig;
    default:
      return engineConfig;
  }
}

export default config();