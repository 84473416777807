import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import './login.css'
import { passwordLogin, autoRegister } from '../utility/apiServices';
import { useDispatch } from 'react-redux'
import { loginSuccessful, startLoader, stopLoader } from './loginSlice'
import { useMediaQuery } from 'react-responsive';

const Login = () => {

    const dispatch = useDispatch()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const onFinish = (values) => {
        dispatch(startLoader())
        passwordLogin(values)
        .then(auth_token => {
          autoRegister(auth_token, values["mobileNumber"])
          dispatch(loginSuccessful({authToken: auth_token, mobileNumber: values["mobileNumber"]}))
          dispatch(stopLoader())
          message.success('successfully Logged In');
        })
        .catch(err => { 
          console.log("err", err)
            dispatch(stopLoader())
            message.error(`Can't Log In`);
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className={isTabletOrMobile ? 'containerDivMobile' : 'containerDiv'}>
            <Card
                title={isTabletOrMobile ? 'Dashboard Password Login' : 'Spicy Wagon Dashboard Password Login'}
                style={{ borderWidth: 5, borderRadius: 5 }}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className={isTabletOrMobile ? "loginMobile" : "login"}
                >
                    <Form.Item
                        label="Mobile No."
                        name="mobileNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Mobile Number!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default Login;