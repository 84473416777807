import React, { useEffect, useState, Fragment } from 'react';
import { Table, DatePicker, Statistic, Typography, Button, Spin, Collapse, Select, Input } from 'antd';
import * as moment from 'moment';
import { useSelector } from 'react-redux'
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import './ordersByDate.css'
import { useHistory, useParams, Link } from "react-router-dom";
import SideBar from '../sideBar/sideBar'
import confirmed from '../../assets/color_user.png'
import notConfirmed from '../../assets/user.png'
import confirmedVendor from '../../assets/color_restaurant.png'
import notConfirmedVendor from '../../assets/restaurant.png'
import cod from '../../assets/cash-on-delivery.png'
import prepaid from '../../assets/prepaid.png'
import { useMediaQuery } from 'react-responsive'

const { Title } = Typography;

const OrdersByDate = (props) => {

    const history = useHistory();
    const params = useParams();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const authToken = useSelector((state) => state.login.authToken)

    const [data, setData] = useState({
        dateSelected: moment(moment(params.date).format('YYYY-MM-DD')),
        orders: [],
        previous: null,
        next: null,
        count: 0
    })
    const [isLoding, setIsLoding] = useState(false)
    const { Panel } = Collapse
    const { Option } = Select
    const [filterObj, setFilterObj] = useState({
        status: null,
        stationCode: null,
        trainNo: null,
        paymentType: null,
        outletId: null,
        search: null
    })
    // const [showFilter, setShowFilter] = useState(false)
    const [ordersByOutletData, setOrdersByOutletData] = useState([])
    const [ordersByStationData, setOrdersByStationData] = useState([])
    const [ordersByTrainData, setOrdersByTrainData] = useState([])

    const fetchOrdersByDate = (date, page) => {
        setIsLoding(true)
        let url = Apis.getOrdersByDate(moment(date).format('YYYY-MM-DD'));
        if (page) {
            url = url + '?page=' + page
        }

        if (filterObj.status) {
            url += '&status=' + filterObj.status
        }

        if (filterObj.stationCode) {
            url += '&station_code=' + filterObj.stationCode
        }

        if (filterObj.trainNo) {
            url += '&train_no=' + filterObj.trainNo
        }

        if (filterObj.paymentType) {
            url += '&payment_type=' + filterObj.paymentType
        }

        if (filterObj.outletId) {
            url += '&outlet_id=' + filterObj.outletId
        }

        if (filterObj.search) {
            url += '&search=' + filterObj.search
        }

        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                const ords = resData.results.map(x => {
                    try {
                        x.name = <>{x.data.customerDetails.customerName}
                            <br />
                            {x.customer_mobile} </>;
                        x.berth = <>{x.data.deliveryDetails.coach} - {x.data.deliveryDetails.berth}
                            <br />
                            {x.data.deliveryDetails.trainName} - {x.data.deliveryDetails.trainNo}
                            <br />
                            {moment(x.eta).format('MM-DD-YYYY HH:mm')}
                        </>;
                        x.station = x.data.deliveryDetails.station;
                        x.trainDetails = x.data.deliveryDetails.trainNo + '-' +
                            x.data.deliveryDetails.trainName;
                        x.amount = <> {x.payment_type === "CASH_ON_DELIVERY" ?
                            <Fragment>
                                <img src={cod} alt="COD" className="tablelogo" />
                                <div>COD</div>
                            </Fragment> :
                            <Fragment>
                                <img src={prepaid} alt="Prepaid" className="tablelogo" />
                                <div>Prepaid</div>
                            </Fragment>}
                            &nbsp;
                            &#8377;{x.data.priceDetails.totalAmount}
                        </>;
                        x.bookingDate = x.data.bookingDate
                        x.deliveryDate = x.data.deliveryDate
                        x.items = x.data.orderItems.map(y => {
                            return <> {y.itemName} ({y.quantity}) <br /> </>
                        })
                        x.passenger_status = x.confirmed_by_user ?
                            <Fragment>
                                <img src={confirmed} alt="Confirmed" className="tablelogo" />
                                <div>Confirmed</div>
                            </Fragment> :
                            <Fragment>
                                <img src={notConfirmed} alt="Not Confirmed" className="tablelogo" />
                                <div>Not Confirmed</div>
                            </Fragment>
                        x.vendor_status = x.status !== 'ORDER_PLACED' ?
                            <Fragment>
                                <img src={confirmedVendor} alt="Confirmed" className="tablelogo" />
                                <div>Confirmed</div>
                            </Fragment> :
                            <Fragment>
                                <img src={notConfirmedVendor} alt="Not Confirmed" className="tablelogo" />
                                <div>Not Confirmed</div>
                            </Fragment>
                        x.vendorDetails = <>
                            {x.outlet_name} <br /> {x.data.deliveryDetails.station} - {x.data.deliveryDetails.stationCode}
                        </>
                        return x
                    } catch (e) {
                        return x
                    }
                })
                setData({
                    ...data,
                    orders: ords,
                    previous: resData.previous,
                    next: resData.next,
                    count: resData.count
                })
                setIsLoding(false)
            })
            .catch(err => {
                console.log("Err", err)
                setIsLoding(false)
            });
    }

    const fetchOrdersByOutlet = (date) => {
        setIsLoding(true)
        let url = Apis.getOutletOrdersByDate(moment(date).format('YYYY-MM-DD'));
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setOrdersByOutletData(resData)
                setIsLoding(false)
            })
            .catch(err => {
                console.log("Err", err)
                setIsLoding(false)
            });
    }

    const fetchOrdersByStation = (date) => {
        setIsLoding(true)
        let url = Apis.getStationOrdersByDate(moment(date).format('YYYY-MM-DD'));
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                setOrdersByStationData(resData)
                setIsLoding(false)
            })
            .catch(err => {
                console.log("Err", err)
                setIsLoding(false)
            });
    }

    const fetchOrdersByTrain = (date) => {
        setIsLoding(true)
        let url = Apis.getTrainOrdersByDate(moment(date).format('YYYY-MM-DD'));
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                const uniqueTrainNo = [...new Set(resData.map(item => item.train_no))];
                const dummy = uniqueTrainNo.map(x => {
                    let y = {}
                    y.trainNo = x;
                    y.trainName = resData.find(z => z.train_no === x).train_name
                    return y
                })
                setOrdersByTrainData(dummy)
                setIsLoding(false)
            })
            .catch(err => {
                console.log("Err", err)
                setIsLoding(false)
            });
    }

    const onFiltersApply = () => {
        fetchOrdersByDate(moment(params.date).format('YYYY-MM-DD'), 1)
    }

    useEffect(() => {
        fetchOrdersByDate(moment(params.date).format('YYYY-MM-DD'), 1)
        fetchOrdersByOutlet(moment(params.date).format('YYYY-MM-DD'))
        fetchOrdersByStation(moment(params.date).format('YYYY-MM-DD'))
        fetchOrdersByTrain(moment(params.date).format('YYYY-MM-DD'))
        //eslint-disable-next-line
    }, []);

    const onOrderClick = (e, id) => {
        // const today = moment(new Date()).format('YYYY-MM-DD')
        return history.push({
            pathname: `/order/id/${id}/date/${moment(params.date).format('YYYY-MM-DD')}`,
            state: { selectedTab: 'orders' }
        });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '',
            dataIndex: 'vendor_status',
            key: 'vendor_status',
            align: 'center'
        },
        {
            title: 'IRCTC ORD ID',
            dataIndex: 'order_id',
            key: 'order_id',
            render: id => <Link to={`/order/id/${id}/date/${moment(params.date).format('YYYY-MM-DD')}`} target="_blank" rel="noopener noreferrer">
                {id}
            </Link>
            ,
        },
        {
            title: '',
            dataIndex: 'passenger_status',
            key: 'passenger_status',
            align: 'center'
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'BERTH',
            dataIndex: 'berth',
            key: 'berth',
        },
        {
            title: 'VENDOR DETAILS',
            dataIndex: 'vendorDetails',
            key: 'vendorDetails',
        },
        {
            title: 'ITEMS',
            dataIndex: 'items',
            key: 'items',
        },
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center'
        },
        {
            title: 'BOOKING DATE',
            dataIndex: 'bookingDate',
            key: 'bookingDate',
            align: 'center'
        },
        {
            title: 'DELIVERY DATE',
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const onChange = (date) => {
        setData({
            ...data,
            dateSelected: date
        })
        fetchOrdersByDate(date, 1)
        fetchOrdersByOutlet(date)
        fetchOrdersByStation(date)
        fetchOrdersByTrain(date)
        return history.push({
            pathname: `/orders/date/${moment(date).format('YYYY-MM-DD')}`,
            state: { selectedTab: 'orders' }
        });
    }

    const ClearFilter = () => {
        setFilterObj({
            ...filterObj,
            status: null,
            stationCode: null,
            trainNo: null,
            paymentType: null,
            outletId: null,
            search: null
        })
    }

    const ordersByDate = <div>
        <Title style={{ textAlign: 'center' }} level={4}>Orders By Date</Title>
        <div className="header">
            <div>
                <Title level={5}>Selected Date</Title>
                <DatePicker
                    onChange={onChange}
                    defaultValue={data.dateSelected}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Statistic style={{ marginRight: 10 }} title="Count" value={data.count} />
            </div>
        </div>
        <div>
            <p style={{ fontWeight: 'bold' }}>
                {
                    (filterObj.status !== null ? "Status: " + filterObj.status + ', ' : '') +
                    (filterObj.stationCode !== null ? "Station Code: " + filterObj.stationCode + ', ' : '') +
                    (filterObj.trainNo !== null ? "Train No: " + filterObj.trainNo + ', ' : '') +
                    (filterObj.paymentType !== null ? "Payment Type: " + filterObj.paymentType + ', ' : '') +
                    (filterObj.outletId !== null ? "Outlet Id: " + filterObj.outletId + ', ' : '') +
                    (filterObj.search !== null ? "Search by Irctc Order ID: " + filterObj.search + ', ' : '')
                }
            </p>
            <Collapse bordered={false}>
                <Panel header="Filters" key="1">
                    <Select
                        showSearch
                        style={{ width: isTabletOrMobile ? '100%' : '45%', margin: 10 }}
                        value={filterObj.status}
                        placeholder="Select Status"
                        optionFilterProp="children"
                        onChange={(value) => setFilterObj({ ...filterObj, status: value })}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        <Option value="ORDER_PLACED">ORDER_PLACED</Option>
                        <Option value="ORDER_PREPARING">ORDER_PREPARING</Option>
                    </Select>
                    <Select
                        showSearch
                        style={{ width: isTabletOrMobile ? '100%' : '45%', margin: 10 }}
                        value={filterObj.stationCode}
                        placeholder="Select Station"
                        optionFilterProp="children"
                        onChange={(value) => setFilterObj({ ...filterObj, stationCode: value })}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {ordersByStationData.map(x => {
                            return <Option value={x.station_code}>{x.station_code} - Count: {x.count}</Option>
                        })
                        }
                    </Select>
                    <Select
                        showSearch
                        style={{ width: isTabletOrMobile ? '100%' : '45%', margin: 10 }}
                        value={filterObj.trainNo}
                        placeholder="Select Train"
                        optionFilterProp="children"
                        onChange={(value) => setFilterObj({ ...filterObj, trainNo: value })}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {ordersByTrainData.map(x => {
                            return <Option value={x.trainNo}>{x.trainNo} - {x.trainName}</Option>
                        })
                        }
                    </Select>
                    <Select
                        showSearch
                        style={{ width: isTabletOrMobile ? '100%' : '45%', margin: 10 }}
                        value={filterObj.paymentType}
                        placeholder="Select Payment Type"
                        optionFilterProp="children"
                        onChange={(value) => setFilterObj({ ...filterObj, paymentType: value })}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        <Option value="CASH_ON_DELIVERY">CASH_ON_DELIVERY</Option>
                        <Option value="PRE_PAID">PRE_PAID</Option>
                    </Select>
                    <Select
                        showSearch
                        style={{ width: isTabletOrMobile ? '100%' : '45%', margin: 10 }}
                        value={filterObj.outletId}
                        placeholder="Select Outlet"
                        optionFilterProp="children"
                        onChange={(value) => setFilterObj({ ...filterObj, outletId: value })}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {ordersByOutletData.map(x => {
                            return <Option value={x.outlet_id}>{x.outlet_name} - {x.station_code} - Count: {x.count}</Option>
                        })
                        }
                    </Select>
                    <Input
                        placeholder="Search by Irctc Order ID"
                        style={{ width: isTabletOrMobile ? '100%' : '45%', margin: 10 }}
                        onChange={(e) => setFilterObj({ ...filterObj, search: e.target.value })}
                        value={filterObj.search}
                    />
                    <div style={{ padding: 10 }}>
                        <Button onClick={onFiltersApply}>Apply</Button>
                        <Button onClick={() => { ClearFilter() }}>Clear Filters</Button>
                    </div>
                </Panel>
            </Collapse>
        </div>
        <div>
            {isLoding === true && <Spin size="large" className="spinner" />}
        </div>
        <Table
            columns={columns}
            dataSource={data.orders}
            size="small"
            className="table"
            pagination={{
                pageSize: 20,
                total: data.count,
                onChange: (pgNo) => fetchOrdersByDate(moment(params.date).format('YYYY-MM-DD'), pgNo)
            }}
            scroll={isTabletOrMobile ? { x: true } : { x: true }}
        />
    </div>

    return (
        <SideBar componentToRender={ordersByDate} />
    )
}

export default OrdersByDate;
