import React from 'react';
import './home.css'
import SideBar from '../sideBar/sideBar'

const Home = () => {

    const home = <div>
        Home
    </div>

    return (
        <SideBar componentToRender={home} />
    )
}

export default Home;