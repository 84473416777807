import { Layout, Menu } from 'antd';
import {
  LogoutOutlined,
  GlobalOutlined,
  UserOutlined,
  ShopOutlined,
  BarChartOutlined,
  BankOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import './sideBar.css'
import { logout as localStorageLogOut } from '../../utility/apiServices'
import { useDispatch } from 'react-redux'
import { logout } from '../../login/loginSlice'
import { useHistory, useLocation } from "react-router-dom";
import * as moment from 'moment';
import LogoSrc from "../../assets/logo.png";
import { useMediaQuery } from 'react-responsive'
// import TrainGIF from "../../assets/train.gif";

const { Header, Content, Footer, Sider } = Layout;

const SideBar = (props) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [collapsed, setCollapsed] = useState(isTabletOrMobile ? true : false)

  const onCollapse = collapsed => {
    setCollapsed(collapsed)
  };

  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();

  const logMeOut = () => {
    dispatch(logout())
    localStorageLogOut()
  }

  const onOrdersByDateClick = () => {
    const today = moment(new Date()).format('YYYY-MM-DD')
    return history.push({
      pathname: `/orders/date/${today}`,
      state: { selectedTab: 'orders' }
    });
  }

  const onFeedbackByDatesClick = () => {
    return history.push({
      pathname: `/feedbacks/all/`,
      state: { selectedTab: 'feedbacks' }
    });
  }

  const onPassengerClick = () => {
    const today = moment(new Date()).format('YYYY-MM-DD')
    return history.push({
      pathname: `/orders/passenger-yet-to-confirm/date/${today}`,
      state: { selectedTab: 'passenger' }
    });
  }

  const onVendorsClick = () => {
    const today = moment(new Date()).format('YYYY-MM-DD')
    return history.push({
      pathname: `/orders/vendor-yet-to-confirm/date/${today}`,
      state: { selectedTab: 'vendors' }
    });
  }

  const onSummaryClick = () => {
    return history.push({
      pathname: `/orders/summary`,
      state: { selectedTab: 'summary' }
    });
  }
  
  const onOutletsClick = () => {
    return history.push({
      pathname: `/outlets`,
      state: { selectedTab: 'outlets' }
    });
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} collapsedWidth={isTabletOrMobile ? 0 : 60} onCollapse={onCollapse}>
        <div className="logo2" />
        <Menu theme="dark" defaultSelectedKeys={[location.state?.selectedTab]} mode="inline">
          <Menu.Item key="orders" icon={<GlobalOutlined />} onClick={onOrdersByDateClick} >
            Orders By Date
          </Menu.Item>
          <Menu.Item key="vendors" icon={<ShopOutlined />} onClick={onVendorsClick} >
            Vendors
          </Menu.Item>
          <Menu.Item key="passenger" icon={<UserOutlined />} onClick={onPassengerClick} >
            Passenger
            </Menu.Item>
            <Menu.Item key="summary" icon={<BarChartOutlined />} onClick={onSummaryClick} >
            Orders Summary
            </Menu.Item>
          <Menu.Item key="feedbacks" icon={<GlobalOutlined />} onClick={onFeedbackByDatesClick} >
            Feedbacks
          </Menu.Item>
          <Menu.Item key="outlets" icon={<BankOutlined />} onClick={onOutletsClick} >
            Outlets
          </Menu.Item>
          <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logMeOut}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={isTabletOrMobile ? {
            padding: 0,
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
          } : {
              padding: 0,
              height: 100,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }
          }
        >
          <img
            src={LogoSrc}
            alt="SpicyWagonLogo"
            className={isTabletOrMobile ? "logoMobileHeader" : "logoDesktopHeader"}
          />
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ minHeight: 360 }}>
            {
              props.componentToRender && props.componentToRender
            }
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Spicy Wagon</Footer>
      </Layout>
    </Layout>
  );
}

export default SideBar