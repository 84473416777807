import axios from 'axios';

const GetMethod = (url = "", headers = {}, body = {}) => {
    const hdrs = { "Content-Type": "application/json;charset=UTF-8", ...headers };
    return axios({
        method: 'get',
        url: url,
        params: { ...body },
        headers: { ...hdrs }
    });
};

const PostMethod = (url = "", body = {}, headers = {}) => {
    const hdrs = { "Content-Type": "application/json;charset=UTF-8", ...headers };
    return axios({
        method: 'post',
        url: url,
        data: body,
        headers: { ...hdrs }
    });
};

const PutMethod = (url = '', body = {}, headers = {}) => {
    const hdrs = { "Content-Type": "application/json;charset=UTF-8", ...headers };
    return axios({
        method: 'put',
        url: url,
        data: body,
        headers: { ...hdrs }
    });
}

const PatchMethod = (url = '', body = {}, headers = {}) => {
    const hdrs = { "Content-Type": "application/json;charset=UTF-8", ...headers };
    return axios({
        method: 'patch',
        url: url,
        data: body,
        headers: { ...hdrs }
    });
}

const DeleteMethod = (url = '', headers = {}) => {
    const hdrs = { "Content-Type": "application/json;charset=UTF-8", ...headers };
    return axios({
        method: 'delete',
        url: url,
        // data: body,
        headers: { ...hdrs }
    });
}

const httpUtil = {
    'Get': GetMethod,
    'Post': PostMethod,
    'Put': PutMethod,
    'Patch': PatchMethod,
    'Delete': DeleteMethod
};

export default httpUtil;