import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Statistic, Typography, Button, Spin, Modal, message } from 'antd';
import * as moment from 'moment';
import { useSelector } from 'react-redux'
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import './ordersByPassenger.css'
import { useHistory, useParams } from "react-router-dom";
import SideBar from '../sideBar/sideBar'
import { CheckCircleTwoTone } from '@ant-design/icons';
import confirmed from '../../assets/color_user.png'
import notConfirmed from '../../assets/user.png'
import confirmedVendor from '../../assets/color_restaurant.png'
import notConfirmedVendor from '../../assets/restaurant.png'
import cod from '../../assets/cash-on-delivery.png'
import prepaid from '../../assets/prepaid.png'
import { useMediaQuery } from 'react-responsive'

const { Title } = Typography;
const { confirm } = Modal;

const OrdersByPassenger = (props) => {

    const history = useHistory();
    const params = useParams();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const authToken = useSelector((state) => state.login.authToken)

    const [data, setData] = useState({
        dateSelected: moment(moment(params.date).format('YYYY-MM-DD')),
        orders: [],
        previous: null,
        next: null,
        count: 0
    })
    const [isLoding, setIsLoding] = useState(false)

    const fetchOrdersByPassenger = (date, page) => {
        setIsLoding(true)
        let url = Apis.passengerYetToConfirm(moment(date).format('YYYY-MM-DD')) + '?ordering=eta';

        if (page) {
            url = url + '&page=' + page
        }

        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                const ords = resData.results.map(x => {
                    try {
                        x.name = x.data.customerDetails.customerName;
                        x.berth =
                            x.station = x.data.deliveryDetails.station;
                        x.journeyDetails =
                            <>{x.data.deliveryDetails.coach} - {x.data.deliveryDetails.berth} <br />
                                {x.data.deliveryDetails.trainNo} - {x.data.deliveryDetails.trainName} <br />
                                {moment(x.eta).format('MM-DD-YYYY HH:mm')}
                            </>;
                        x.amount = x.data.priceDetails.totalAmount;
                        x.bookingDate = x.data.bookingDate
                        x.deliveryDate = x.data.deliveryDate;
                        x.confirmButton = {
                            order_id: x.data.orderId,
                            confirmDisable: (x.status === 'ORDER_PLACED' ? false : true)
                        };
                        x.etaFormatted = moment(x.eta).format('MM-DD-YYYY HH:mm')
                        x.items = x.data.orderItems.map(y => {
                            return <> {y.itemName} ({y.quantity}) <br /> </>
                        })
                        x.passenger_status = x.confirmed_by_user ?
                            <img src={confirmed} alt="Confirmed" className="tablelogo" /> :
                            <img src={notConfirmed} alt="Not Confirmed" className="tablelogo" />
                        x.vendor_status = x.status !== 'ORDER_PLACED' ?
                            <img src={confirmedVendor} alt="Confirmed" className="tablelogo" /> :
                            <img src={notConfirmedVendor} alt="Not Confirmed" className="tablelogo" />
                        x.vendorDetails = <>
                            {x.outlet_name} <br /> {x.data.deliveryDetails.station} - {x.data.deliveryDetails.stationCode}
                        </>
                        x.passengerDetails = <>{x.data.customerDetails.customerName}
                            <br />
                            {x.customer_mobile} </>;
                        x.amount = <> {x.payment_type === "CASH_ON_DELIVERY" ?
                            <img src={cod} alt="COD" className="tablelogo" /> :
                            <img src={prepaid} alt="Prepaid" className="tablelogo" />}
                        &nbsp;
                        &#8377;{x.data.priceDetails.totalAmount}
                        </>;
                        x.dateCompo = <> BD: {x.data.bookingDate} <br /> DD: {x.data.deliveryDate}</>
                        return x
                    } catch (e) {
                        return x
                    }
                })
                setData({
                    ...data,
                    orders: ords,
                    previous: resData.previous,
                    next: resData.next,
                    count: resData.count
                })
                setIsLoding(false)
            })
            .catch(err => {
                console.log("Err", err)
                setIsLoding(false)
            });
    }

    useEffect(() => {
        fetchOrdersByPassenger(moment(params.date).format('YYYY-MM-DD'), 1)
        //eslint-disable-next-line
    }, []);

    const onOrderClick = (e, id) => {
        // const today = moment(new Date()).format('YYYY-MM-DD')
        return history.push({
            pathname: `/order/id/${id}/date/${moment(params.date).format('YYYY-MM-DD')}`,
            state: { selectedTab: 'orders' }
        });
    }

    const showPromiseConfirm = (e, id, pnr) => {
        console.log("prn", pnr)
        confirm({
            title: 'Do you want to confirm the order?',
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            content: 'When clicked the OK button, this order will be confirmed by the operator',
            onOk() {
                confirmOrder(e, id, pnr);
            },
            onCancel() { },
        });
    }

    const confirmOrder = (e, id, pnr) => {
        let url = Apis.passengerConfirmUnAuth(pnr, id);
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                console.log("resData", resData)
                fetchOrdersByPassenger(moment(params.date).format('YYYY-MM-DD'))
                message.success('Order Confirmed');
                return resData
            })
            .catch(err => {
                message.error(`Can't Confirm Order`);
                return err
            });
    }

    const columns = [

        {
            title: 'IRCTC ORD ID',
            dataIndex: 'order_id',
            key: 'order_id',
            render: id => <Button type="link" block onClick={(event) => onOrderClick(event, id)}>
                {id}
            </Button>,
        },
        {
            title: '',
            dataIndex: 'vendor_status',
            key: 'vendor_status',
        },
        {
            title: 'CONFIRM',
            dataIndex: 'confirmButton',
            key: 'confirmButton',
            render: (id) => <Button
                size='large'
                type="link"
                disabled={id.confirmed_by_user}
                block
                onClick={(event) => showPromiseConfirm(event, id.order_id, id.pnr)}
            >
                Confirm
            </Button>
        },
        {
            title: <>PASSENGER <br /> DETAILS</>,
            dataIndex: 'passengerDetails',
            key: 'passengerDetails',
        },
        {
            title: 'ITEMS',
            dataIndex: 'items',
            key: 'items',
        },
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center'
        },
        {
            title: <>VENDOR <br /> DETAILS</>,
            dataIndex: 'vendorDetails',
            key: 'vendorDetails',
        },
        {
            title: <>JOURNEY <br /> DETAILS</>,
            dataIndex: 'journeyDetails',
            key: 'journeyDetails',
        },
        {
            title: <>BOOKING DATE <br /> DELIVERY DATE</>,
            dataIndex: 'dateCompo',
            key: 'dateCompo',
        },
    ];

    const onChange = (date) => {
        setData({
            ...data,
            dateSelected: date
        })
        fetchOrdersByPassenger(date, 1)
        return history.push({
            pathname: `/orders/passenger-yet-to-confirm/date/${moment(date).format('YYYY-MM-DD')}`,
            state: { selectedTab: 'passenger' }
        });
    }

    const ordersByPassenger = <div>
        <Title style={{ textAlign: 'center' }} level={4}>Orders By Date</Title>
        <div className="header">
            <div>
                <Title level={5}>Selected Date</Title>
                <DatePicker
                    onChange={onChange}
                    defaultValue={data.dateSelected}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Statistic style={{ marginRight: 10 }} title="Count" value={data.count} />
            </div>
        </div>
        <div>
            {isLoding === true && <Spin size="large" className="spinner" />}
        </div>
        <Table
            columns={columns}
            dataSource={data.orders}
            size="small"
            className="table"
            scroll={isTabletOrMobile?{ x: true}:{ x: true, y: 500}}
            pagination={{
                pageSize: 20,
                total: data.count,
                onChange: (pgNo) => fetchOrdersByPassenger(moment(params.date).format('YYYY-MM-DD'), pgNo)
            }}
        />
    </div>

    return (
        <SideBar componentToRender={ordersByPassenger} />
    )
}

export default OrdersByPassenger;