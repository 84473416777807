import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import PageRoutes from './pageRoutes';
import Login from '../login/login';
import SideBar from '../components/sideBar/sideBar';
import OrdersByDate from '../components/ordersByDate/ordersByDate';
import { useSelector } from 'react-redux';
import OrderDetails from '../components/orderDetails/orderDetails';
import OrdersByPassenger from '../components/ordersByPassenger/ordersByPassenger';
import OrdersByVendor from '../components/ordersByVendor/ordersByVendor';
import Outlets from '../components/outlets/outlets';
import OutletDetails from '../components/outletDetails/outletDetails';
import Home from '../components/home/home';
import OrdersSummary from '../components/ordersSummary/ordersSummary';
import FeedbackByDates from '../components/FeedbackByDates/FeedbackByDates';

const history = createBrowserHistory();

const AppRoutes = (props) => {

    const authToken = useSelector((state) => state.login.authToken)

    let routes = <Switch>
        <Route path={PageRoutes.login}>
            <Login />
        </Route>
        <Route path='/'>
            <Login />
        </Route>
    </Switch>

    if (authToken) {
        routes = <Switch>
            <Route path={PageRoutes.home} component={SideBar} />
            {/* <Route exact path="/">
                <Redirect to={PageRoutes.ordersByDate} />
            </Route> */}
            <Route
                path={PageRoutes.ordersByDate}
                component={OrdersByDate}
            />
            {/* <Route
                path={PageRoutes.ordersForStationByDate}
                component={() =>
                    <SideBar componentToRender={() => <OrdersByStation />}
                    />}
            />
            <Route
                path={PageRoutes.ordersForTrainByDate}
                component={() =>
                    <SideBar componentToRender={() => <OrdersByTrain />}
                    />}
            />
            <Route
                path={PageRoutes.ordersForOutletByDate}
                component={() =>
                    <SideBar componentToRender={() => <OrdersByOutlet />}
                    />}
            /> */}
            <Route
                path={PageRoutes.outlets}
                component={Outlets}
            />
            <Route
                path={PageRoutes.outletDetails}
                component={OutletDetails}
            />
            <Route
                path={PageRoutes.unconfirmedByPassenger}
                component={OrdersByPassenger}
            />
            <Route
                path={PageRoutes.unconfirmedByVendor}
                component={OrdersByVendor}
            />
             <Route
                path={PageRoutes.orderDetails}
                component={OrderDetails}
            />
            <Route
                path={PageRoutes.ordersSummary}
                component={OrdersSummary}
            />
            <Route
                path={PageRoutes.feedbackByDates}
                component={FeedbackByDates}
            />
            <Route
                path='/'
                component={Home}
            />
        </Switch>
    }

    return (
        <Router history={history}>
            {routes}
        </Router>
    )
}

export default AppRoutes;