import './App.css';
import React, { useEffect } from 'react';
import AppRoutes from '../src/routing/router'
import { checkAuthInfo } from '../src/utility/apiServices'
import LocalStorageVariables from '../src/config/localStorage';
import { useSelector } from 'react-redux'
import { Spin } from 'antd';
import { loginSuccessful } from '../src/login/loginSlice'
import { useDispatch } from 'react-redux'

const App = () => {

  const loading = useSelector((state) => state.login.loading)

  const dispatch = useDispatch()
  useEffect(() => {
    checkAuthInfo()
      .then(res => {
        if (res.isLoggedIn) {
          dispatch(loginSuccessful({authToken: res.token, mobileNumber: res.mobileNumber}))
        }
      })
  }, [dispatch]);

  const isAuthenticated = localStorage.getItem(LocalStorageVariables.auth_token)

  return (
    <div>
      {
        loading && <Spin className="spinner" />
      }
      <AppRoutes isAuthenticated={isAuthenticated} />
    </div>
  );
}

export default App;