import React, { useState } from 'react';
import { Typography, Button, Image } from 'antd';
import './outlets.css'
import SideBar from '../sideBar/sideBar'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const { Title } = Typography;

const Outlets = () => {

    const history = useHistory();

    const [rowData, setRowData] = useState(null);

    const onGridReady = () => {
        let url = Apis.getOutlets
        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then((resp) => {
                return resp.data
            })
            .then((data) => {
                setRowData(data)
            });
    };

    const authToken = useSelector((state) => state.login.authToken)

    const onOutletsRowClicked = (id) => {
        return history.push({
            pathname: `/outletDetails/outletId/${id}`,
            state: { selectedTab: 'outlets' }
        });
    }

    const MyRenderer = (params) => {
        return (
            params.value ?
                <Image
                    // width={200}
                    height={42}
                    src={params.value}
                />
                : ''
        )
    }

    const Details = (params) => {
        return (
            <Button onClick={() => onOutletsRowClicked(params.data.outlet_id)} shape="round" type="primary">
                Details
            </Button>
        )
    }

    const outlets = <div style={{ width: '100%', height: '600px' }}>
        <Title level={4}>Outlets</Title>
        <div
            id="myGrid"
            style={{
                height: '100%',
                width: '100%',
            }}
            className="ag-theme-alpine"
        >
            <AgGridReact
                defaultColDef={{
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    // rowHeight: '100px'
                }}
                onGridReady={onGridReady}
                rowData={rowData}
            >
                <AgGridColumn field="logoImage" cellRendererFramework={MyRenderer} />
                <AgGridColumn field="name" />
                <AgGridColumn field="is_active" />
                <AgGridColumn field="station_code" />
                <AgGridColumn field="minOrderAmount" />
                <AgGridColumn field="fssaiValidUpto" sor/>
                <AgGridColumn field="details" cellRendererFramework={Details} />
                <AgGridColumn field="openingTime" />
                <AgGridColumn field="closingTime" />
                <AgGridColumn field="featured" />
                <AgGridColumn field="average_rating" />
                <AgGridColumn field="n_rating" />

            </AgGridReact>
        </div>
    </div>

    return (
        <SideBar componentToRender={outlets} />
    )
}

export default Outlets;