const pageRoutes = {
    login: '/login',
    home: '/home',
    ordersByDate: '/orders/date/:date',
    ordersForStationByDate: '/orders/station/date/:date',
    ordersForTrainByDate: '/orders/train/date/:date',
    ordersForOutletByDate: '/orders/outlet/date/:date',
    orderDetails: '/order/id/:id/date/:date',
    unconfirmedByPassenger: '/orders/passenger-yet-to-confirm/date/:date',
    unconfirmedByVendor: '/orders/vendor-yet-to-confirm/date/:date',
    ordersSummary: '/orders/summary',
    outlets: '/outlets',
    outletDetails: '/outletDetails/outletId/:outletId',
    feedbackByDates: '/feedbacks/all/',
}

export default pageRoutes;