import React from 'react';
import { Tabs } from 'antd';
import './login.css'
import LoginPassword from './loginPassword';
import LoginOTP from './loginOTP';

const { TabPane } = Tabs;

const Login = () => {

    const callback = (key) => {
        console.log("key", key)
    }

    return (
        <div className="default" >
            <Tabs defaultActiveKey="1" onChange={callback} centered>
                <TabPane tab="Password Login" key="1">
                    <LoginPassword />
                </TabPane>
                <TabPane tab="OTP Login" key="2">
                    <LoginOTP />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Login;