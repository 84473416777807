import React, { useEffect, useState, Fragment } from 'react';
import { Table, DatePicker, Statistic, Typography, Button, Spin, Collapse, Select, Input, List } from 'antd';
import * as moment from 'moment';
import { useSelector } from 'react-redux'
import HttpUtils from '../../utility/httpUtils';
import Apis from '../../config/apiEndpoints';
import './FeedbackByDates.css'
import { useHistory, useParams } from "react-router-dom";
import SideBar from '../sideBar/sideBar'
import confirmed from '../../assets/color_user.png'
import notConfirmed from '../../assets/user.png'
import confirmedVendor from '../../assets/color_restaurant.png'
import notConfirmedVendor from '../../assets/restaurant.png'
import cod from '../../assets/cash-on-delivery.png'
import prepaid from '../../assets/prepaid.png'
import { useMediaQuery } from 'react-responsive'

const { Title } = Typography;
const { RangePicker } = DatePicker

const FeedbackByDates = (props) => {

    const history = useHistory();
    const params = useParams();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [startDate, setStartDate] = useState(moment(moment(new Date()).subtract(20, 'days')).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(moment(new Date()).subtract(5, 'days')).format('YYYY-MM-DD'));
    const authToken = useSelector((state) => state.login.authToken)

    useEffect(() => {
        fetchFeedbackByDates(startDate, endDate)
        //eslint-disable-next-line
    }, []);
    const [isLoding, setIsLoding] = useState(false)
    var [data, setData] = useState([]);

    const fetchFeedbackByDates = (startDate, endDate) => {
        setIsLoding(true)
        let url = Apis.getFeedbackByDates(startDate, endDate);
        

        HttpUtils.Get(url,
            { Authorization: 'Token ' + authToken })
            .then(res => {
                const resData = JSON.parse(JSON.stringify(res))['data'];
                
                var data = []
                for (const [key, obj] of Object.entries(resData)) {
         
                    const temp_obj = {
                        'outlet_id': key,
                        'avg_rating': Math.round((obj.avg_rating? obj.avg_rating : 0)*100 )/100,
                        'feedback_count': obj.feedback_count,
                        'complaint_count': obj.complaint_count,
                        'orders_count': obj.orders_count,
                        'name': obj.outlet ? obj.outlet.name: '',
                        'station_code': obj.outlet ? obj.outlet.station_code: '',
                        'delivered_by': obj.outlet ? obj.outlet.delivered_by: '',
                        'avg_complaint': obj.orders_count ?  (Math.round(obj.complaint_count/obj.orders_count *100) /100) : -1
                    }
                    data.push(temp_obj)

                
                  }
                setData(data)
                setIsLoding(false)
            })
            .catch(err => {
                console.log("Err", err)
                setIsLoding(false)
            });
    }

    const onRangeChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'outlet_id',
            key: 'outlet_id',
        },
        {
            title: 'Outlet Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: 'Avg Rating',
            dataIndex: 'avg_rating',
            key: 'avg_rating',
            sorter: (a, b) => a.avg_rating - b.avg_rating,
           
        },
        {
            title: 'Feedbacks Count',
            dataIndex: 'feedback_count',
            key: 'feedback_count',
            align: 'feedback_count',
            sorter: (a, b) => a.feedback_count - b.feedback_count,
        },
        {
            title: 'Average Complaint',
            dataIndex: 'avg_complaint',
            key: 'avg_complaint',
            sorter: (a, b) => a.avg_complaint - b.avg_complaint,
        },
        {
            title: 'Complaints Count',
            dataIndex: 'complaint_count',
            key: 'complaint_count',
            sorter: (a, b) => a.complaint_count - b.complaint_count,
        },
        {
            title: 'Orders Count',
            dataIndex: 'orders_count',
            key: 'orders_count',
            sorter: (a, b) => a.orders_count - b.orders_count,
        },
        {
            title: 'Station Code',
            dataIndex: 'station_code',
            key: 'station_code',
        },
        {
            title: 'Delivered By',
            dataIndex: 'delivered_by',
            key: 'delivered_by',
        }
    ];


    const FeedbackByDates = <div>
        <Title style={{ textAlign: 'center' }} level={4}>Feedbacks With In Selected Dates </Title>
        <div className="header">
            <div>
                <RangePicker
                    defaultValue={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                    format={'YYYY-MM-DD'}
                    onChange={onRangeChange}
                />
                <Button onClick={() => fetchFeedbackByDates(startDate, endDate)}>Go</Button>
            </div>
        </div>
        <div>
            {isLoding === true && <Spin size="large" className="spinner" />}
        </div>
        <Table
            columns={columns}
            dataSource={data}
            size="small"
            className="table"
            pagination={{
                pageSize: 500,
                onChange: (pgNo) => fetchFeedbackByDates(startDate, endDate, pgNo)
            }}
            scroll={isTabletOrMobile ? { x: true } : { x: true }}
        />
    </div>

    return (
        <SideBar componentToRender={FeedbackByDates} />
    )
}

export default FeedbackByDates;