import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    mobileNumber: null,
    authToken: null,
    loading: false
  },
  reducers: {
    startLoader: (state) => {
      state.loading = true
    },
    stopLoader: (state) => {
      state.loading = false
    },
    loginSuccessful: (state, action) => {
      state.mobileNumber = action.payload.mobileNumber
      state.authToken = action.payload.authToken
    },
    logout: (state) => {
      state.mobileNumber = null
      state.authToken = null
    }
  },
})

// Action creators are generated for each case reducer function
export const { loginSuccessful, logout, startLoader, stopLoader } = counterSlice.actions

export default counterSlice.reducer